import moment from 'moment';
import React from 'react';
import {Message} from 'semantic-ui-react';
import {customerStyles} from './customer-styles';
import {Form} from '../forms';
import {
  MeterReadingsService,
  AccountForCustomer,
  MeterReadingSummaryDto,
} from '../api/generated';
import {isInclusivelyBeforeDay, isInclusivelyAfterDay} from 'react-dates';
import {SkipReadingModal} from './skip-reading-modal';

type MeterReadingInput = {
  account: AccountForCustomer;
  lastMeterReading: MeterReadingSummaryDto;
  setMeterReading: (MeterReadingSummaryDto) => void;
};

export const MeterReadingInput = (props: MeterReadingInput) => {
  const handleMeterReadingSubmission = async (values) => {
    values.accountId = props.account.id;
    values.calculateAmountDue = true;

    const response = await MeterReadingsService.create({body: values});

    if (!response.data) {
      return response;
    }

    if (response.hasErrors) {
      return response;
    }

    props.setMeterReading(response.data);
  };

  return (
    <>
      {moment(props.lastMeterReading.readingDate).format('MM/DD/YYYY') !==
      moment().format('MM/DD/YYYY') ? (
        <>
          <div className="customer-header-content">
            <span className="account-information org-name">
              {props.account.organizationName}
            </span>
            <span className="customer-name">{props.account.name}</span>
            <span className="account-information muted">
              <span>Account #: </span>
              {props.account.accountNumber}
            </span>
          </div>

          <div className="divider" />

          <div className="previous-meter-reading-container">
            <div className="previous-meter-reading">
              <div className="title">Previous Meter Reading</div>
              <div className="reading-information">
                <div className="date">
                  <span className="small-text">Date: </span>
                  <span className="medium-text">
                    {moment(props.lastMeterReading.readingDate).format(
                      'MM/DD/YYYY'
                    )}
                  </span>
                </div>
                <div className="amount">
                  <span className="small-text">Reading Amount: </span>
                  <span className="medium-text">
                    {props.lastMeterReading.readingAmount}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="divider" />

          <div className="new-meter-reading">
            <Form
              onSubmit={handleMeterReadingSubmission}
              render={() => (
                <>
                  <div className="title">Current Meter Reading</div>
                  <Form.Row>
                    <Form.DatePicker
                      fieldName="readingDate"
                      fieldLabel="Reading Date"
                      isOutsideRange={(date) =>
                        isInclusivelyBeforeDay(
                          date,
                          moment(props.lastMeterReading.readingDate)
                        ) || isInclusivelyAfterDay(date, moment().add(1, 'day'))
                      }
                      withPortal
                    />
                    <Form.Input
                      type="number"
                      fieldName="readingAmount"
                      fieldLabel="Reading Amount"
                      min="0"
                    />
                  </Form.Row>
                  <Form.Row>
                    <Form.Input
                      fieldName="comments"
                      fieldLabel="Comments (optional)"
                    />
                  </Form.Row>
                  <div className="terms-of-service">
                    <span className="tiny-text">
                      By submitting this form, you affirm you read your meter
                      and the reading you entered is accurate.
                    </span>
                  </div>
                  <Form.Button
                    type="submit"
                    primary
                    fluid
                    className="submit-button"
                  >
                    Submit Reading
                  </Form.Button>
                  {props.account.alwaysAllowPayments && (
                    <SkipReadingModal accountId={Number(props.account.id)} />
                  )}
                </>
              )}
            />
          </div>
        </>
      ) : (
        <div css={customerStyles}>
          <Message positive className="centered-meter-message-body-content">
            There has already been a meter reading submitted for this day.
          </Message>
        </div>
      )}
    </>
  );
};
