import React, {useMemo} from 'react';
import {useState} from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {Divider, Form as SUIForm} from 'semantic-ui-react';
import {AccountsService, RateTablesService} from '../api/generated';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {DeleteButton} from '../components/confirm-delete-button';
import {Form} from '../forms';
import {useNotification} from '../hooks/use-notifications';
import {routes, buildPath} from '../routes';
import {useOrganizationContext} from '../selectors';

export const AccountCreate = () => {
  const history = useHistory();
  const notifications = useNotification();
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const [manual, setManual] = useState<boolean>();

  const fetchRateTables = useAsync(async () => {
    const {data} = await RateTablesService.getAllByOrganization({
      organizationId,
    });

    if (data === null) return null;

    return data.items;
  });

  const rateTables = fetchRateTables.value || undefined;

  const mappedRateTables = useMemo(
    () =>
      (rateTables || []).map((x) => ({
        key: `${x.name}`,
        text: `${x.name}`,
        value: (x.id as number) || undefined,
      })),
    [rateTables]
  );

  const breadcrumbs = [
    {title: 'Accounts', url: routes.portal.accounts.listing},
    {title: 'Create'},
  ];

  const onSubmit = async (values) => {
    values.organizationId = organizationId;
    values.rateTableId = !manual ? values.rateTableId : null;
    values.rateCode = manual ? values.rateCode : null;
    var confirmCreate = true;

    if (values.rateCode) {
      const rateTableByRateCodeResponse = await RateTablesService.getByRateCode(
        {rateCode: values.rateCode, organizationId: organizationId}
      );
      if (!rateTableByRateCodeResponse || !rateTableByRateCodeResponse.data) {
        confirmCreate = window.confirm(
          'There seems to be no rate table associated with the inputted rate code. You will need to manually add a rate table with the same rate code in order to submit readings.'
        );
      }
    }

    if (!values.readingAmount) {
      confirmCreate = window.confirm(
        'This will create an account without an initial meter reading. You will need to add a meter reading manually if you proceed.'
      );
    }

    if (confirmCreate) {
      const response = await AccountsService.create({body: values});
      if (response.hasErrors) {
        return response;
      }

      notifications.success('Account Created');
      history.push(routes.portal.accounts.listing);
    }
  };

  return (
    <BasicPage title={breadcrumbs}>
      <Form.Container>
        <Form
          onSubmit={onSubmit}
          render={() => (
            <>
              <Form.Section title="Account Information">
                <FormFields
                  setManual={setManual}
                  manual={manual}
                  rateTableOptions={mappedRateTables}
                  isCreate={true}
                />
              </Form.Section>
              <div className="form-actions">
                <Form.Button type="submit" primary>
                  Create Account
                </Form.Button>
                <Form.Button
                  secondary
                  as={Link}
                  to={routes.portal.accounts.listing}
                >
                  Cancel
                </Form.Button>
              </div>
            </>
          )}
        />
      </Form.Container>
    </BasicPage>
  );
};

export const AccountUpdate = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const notifications = useNotification();
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const [manual, setManual] = useState<boolean>();

  const fetchAccount = useAsync(async () => {
    const {data} = await AccountsService.getById({
      id,
    });

    if (!data?.rateTableId) {
      setManual(true);
    }

    return data;
  }, [id]);

  const account = fetchAccount.value || undefined;

  const fetchRateTables = useAsync(async () => {
    const {data} = await RateTablesService.getAllByOrganization({
      organizationId,
    });

    if (data === null) return null;

    return data.items;
  });

  const rateTables = fetchRateTables.value || undefined;

  const mappedRateTables = useMemo(
    () =>
      (rateTables || []).map((x) => ({
        key: `${x.name}`,
        text: `${x.name}`,
        value: x.id as number | undefined,
      })),
    [rateTables]
  );

  const breadcrumbs = [
    {title: 'Accounts', url: routes.portal.accounts.listing},
    {title: account ? account.name : ''},
  ];

  const onSubmit = async (values) => {
    values.rateTableId = !manual ? values.rateTableId : null;
    values.rateCode = manual ? values.rateCode : null;
    var confirmCreate = true;

    if (values.rateCode) {
      const rateTableByRateCodeResponse = await RateTablesService.getByRateCode(
        {rateCode: values.rateCode, organizationId: organizationId}
      );

      if (
        (!rateTableByRateCodeResponse || !rateTableByRateCodeResponse.data) &&
        confirmCreate
      ) {
        confirmCreate = window.confirm(
          'There seems to be no rate table associated with the inputted rate code. You will need to manually add a rate table with the same rate code in order to submit readings.'
        );
      }
    }
    const response = await AccountsService.update({id, body: values});
    if (response.hasErrors) {
      return response;
    }
    notifications.success('Account Updated');
    history.push(buildPath(routes.portal.accounts.dashboard, {id}));
  };

  const [deleteAccountState, deleteAccount] = useAsyncFn(async () => {
    const response = await AccountsService.deleteById({id});
    if (response.hasErrors) {
      var errorMessage = response.errors.reduce((acc, item) => {
        return `${acc} \n ${item.errorMessage}`;
      }, '');

      notifications.error(errorMessage);
    } else {
      notifications.success('Account successfully deleted');
      history.push(routes.portal.accounts.listing, {id: id});
    }
  });

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchAccount}>
        <Form.Container>
          <Form
            initialValues={account}
            onSubmit={onSubmit}
            render={({values}) => (
              <>
                <Form.Section title="Account Information">
                  <FormFields
                    setManual={setManual}
                    manual={manual}
                    rateTableOptions={mappedRateTables}
                    isCreate={false}
                  />
                </Form.Section>
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Update Account
                  </Form.Button>
                  <Form.Button
                    secondary
                    as={Link}
                    to={buildPath(routes.portal.accounts.dashboard, {id})}
                  >
                    Cancel
                  </Form.Button>

                  <DeleteButton
                    onConfirm={deleteAccount}
                    loading={deleteAccountState.loading}
                    tertiary
                    icon={false}
                  />
                </div>
              </>
            )}
          />
        </Form.Container>
      </AsyncStateContainer>
    </BasicPage>
  );
};

type FormFields = {
  rateTableOptions: {
    key: string;
    text: string;
    value: number | undefined;
  }[];
  isCreate: boolean;
  manual: boolean | undefined;
  setManual: (checked: boolean | undefined) => void;
};

const FormFields = (props: FormFields) => {
  return (
    <>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldName="accountNumber" fieldLabel="Account Number *" />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldName="name" fieldLabel="Name *" />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldName="email" fieldLabel="Email" />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.InputMasked
          fieldName="phoneNumber"
          fieldLabel="Phone Number"
          placeholder="XXX-XXX-XXXX"
          options={{
            phone: true,
            phoneRegionCode: 'US',
            delimiter: '-',
          }}
        />
      </Form.Row>
      <Divider />
      {props.isCreate && (
        <Form.Row proportions={[1, 1]}>
          <Form.Input
            type="number"
            fieldName="readingAmount"
            fieldLabel="Reading Amount"
          />
        </Form.Row>
      )}
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldName="meterNumber" fieldLabel="Meter Number" />
      </Form.Row>

      <SUIForm.Field
        style={{marginBottom: '0px'}}
        label="Add Rate Code Manually"
      />
      <SUIForm.Checkbox
        toggle
        checked={props.manual}
        onChange={(d, {checked}) => {
          props.setManual(checked);
        }}
      />
      {props.manual ? (
        <Form.Row proportions={[1, 1]}>
          <Form.Input fieldName="rateCode" fieldLabel="Rate Code *" />
        </Form.Row>
      ) : (
        <Form.Row proportions={[1, 1]}>
          <Form.Dropdown
            fieldLabel="Rate Table *"
            fieldName="rateTableId"
            options={props.rateTableOptions}
            selection
          />
        </Form.Row>
      )}
    </>
  );
};
