import React from 'react';
import { useFormState } from 'react-final-form';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {RateTableDetailDto, RateTablesService} from '../api/generated';
import {UnitTypes} from '../api/generated/enums';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {DeleteButton} from '../components/confirm-delete-button';
import {Form} from '../forms';
import {useNotification} from '../hooks/use-notifications';
import {routes} from '../routes';
import {useOrganizationContext} from '../selectors';

export const RateTableCreate = () => {
  const history = useHistory();
  const notifications = useNotification();
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const breadcrumbs = [
    {title: 'Rate Tables', url: routes.portal.rateTables.listing},
    {title: 'Create'},
  ];

  const onSubmit = async (values) => {
    values.organizationId = organizationId;

    const response = await RateTablesService.create({body: values});
    if (response.hasErrors) {
      return response;
    }

    notifications.success('Rate Table Created');
    history.push(routes.portal.rateTables.listing);
  };

  return (
    <BasicPage title={breadcrumbs}>
      <Form.Container>
        <Form
          onSubmit={onSubmit}
          render={({values}) => (
            <>
              <Form.Section title="Rate Table Information">
                <FormFields values={values} />
              </Form.Section>
              <div className="form-actions">
                <Form.Button type="submit" primary>
                  Create Rate Table
                </Form.Button>
                <Form.Button
                  secondary
                  as={Link}
                  to={routes.portal.rateTables.listing}
                >
                  Cancel
                </Form.Button>
              </div>
            </>
          )}
        />
      </Form.Container>
    </BasicPage>
  );
};

export const RateTableUpdate = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const notifications = useNotification();

  const fetchRateTable = useAsync(async () => {
    const {data} = await RateTablesService.getById({
      id,
    });
    return data;
  }, [id]);

  const rateTable = fetchRateTable.value || undefined;

  const breadcrumbs = [
    {title: 'Rate Tables', url: routes.portal.rateTables.listing},
    {title: rateTable ? rateTable.name : ''},
  ];

  const onSubmit = async (values) => {
    const response = await RateTablesService.update({id, body: values});
    if (response.hasErrors) {
      return response;
    }
    notifications.success('Rate Table Updated');
    history.goBack();
  };

  const [deleteRateTableState, deleteRateTable] = useAsyncFn(async () => {
    const response = await RateTablesService.deleteById({id});
    if (response.hasErrors) {
      var errorMessage = response.errors.reduce((acc, item) => {
        return `${acc} \n ${item.errorMessage}`;
      }, '');

      notifications.error(errorMessage);
    } else {
      notifications.success('Rate Table successfully deleted');
      history.push(routes.portal.rateTables.listing, {id: id});
    }
  });

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchRateTable}>
        <Form.Container>
          <Form
            initialValues={rateTable}
            onSubmit={onSubmit}
            render={({values}) => (
              <>
                <Form.Section title="Rate Table Information">
                  <FormFields values={values} />
                </Form.Section>
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Update Rate Table
                  </Form.Button>
                  <Form.Button
                    secondary
                    as={Link}
                    to={routes.portal.rateTables.listing}
                  >
                    Cancel
                  </Form.Button>

                  <DeleteButton
                    onConfirm={deleteRateTable}
                    loading={deleteRateTableState.loading}
                    tertiary
                    icon={false}
                  />
                </div>
              </>
            )}
          />
        </Form.Container>
      </AsyncStateContainer>
    </BasicPage>
  );
};

const FormFields = ({values}: {values?: RateTableDetailDto}) => {
  const Units = {
    10: 10,
    100: 100,
    1000: 1000,
  } as const;

  return (
    <>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldName="name" fieldLabel="Name" />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldName="rateCode" fieldLabel="Rate Code" />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Checkbox
          fieldName="doNotImport"
          fieldLabel="Do Not Import This Rate Code"
        />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Checkbox
          fieldName="manuallyCalculated"
          fieldLabel="Amount Manually Calculated"
        />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Checkbox
          fieldName="roundingEnabled"
          fieldLabel="Rounding Enabled"
        />
      </Form.Row>
      {values && values.roundingEnabled && (
        <Form.Row proportions={[1, 1]}>
          <Form.Dropdown
            options={[0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9].map(x => ({
              text: `${x * 100}%`,
              value: x,
              key: x,
            }))}
            selection
            fieldName="roundUpAt"
            fieldLabel="Round Up At"
          />
        </Form.Row>
      )}
      <Form.Row proportions={[1, 1]}>
        <Form.Dropdown
          enum={Units}
          selection
          fieldName="units"
          fieldLabel="Units"
        />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Dropdown
          enum={UnitTypes}
          selection
          fieldName="unitMeasurement"
          fieldLabel="Unit Measurement"
        />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.InputCurrency
          fieldName="minimumAmount.value"
          fieldLabel="Minimum Amount"
        />
      </Form.Row>
    </>
  );
};
