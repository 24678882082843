import {getUser, initializeUserManager, mapOidcUser, userManager} from '.';
import React, {createContext, useContext, useEffect} from 'react';
import {Dimmer, Loader} from 'semantic-ui-react';
// import {v4 as uuidv4} from 'uuid';
import {DelayedLoadingContainer} from '../components/delayed-loading-container';
import {Env} from '../config/env-vars';
import {useMemoizedValue} from '../hooks/use-memoized-value';
// import {routes} from '../routes';
import {User} from '../types';
import {logger} from '../utils/logger';

import {
  actionCreatorFactory,
  createReducer,
  useImmerReducer,
} from '../hooks/use-immer-reducer';
import {useLocation, useRouteMatch} from 'react-router-dom';
import {rootRoutes} from '../routes';
import {AuthenticationQueryKeys} from '../api/generated/enums';

const log = logger('auth');

type AuthState = {
  user: User | null;
  pending: boolean;
  error: Error | null;
  redirectUrl?: string;
};

type OidcUser = Parameters<typeof mapOidcUser>[0];

const action = actionCreatorFactory<AuthState>();
const onUserLoaded = action<OidcUser>('ON_USER_LOADED');
const onUserUnloaded = action('ON_USER_UNLOADED');
const redirecting = action('REDIRECTING');

const reducer = createReducer<AuthState>()
  .when(onUserLoaded, (draft, user) => {
    draft.pending = false;
    draft.user = mapOidcUser(user);
    const {url} = user.state || {};
    if (url) {
      draft.redirectUrl = url;
    }
  })
  .when(onUserUnloaded, (draft) => {
    draft.pending = true;
    draft.user = null;
  })
  .when(redirecting, (draft) => {
    draft.pending = true;
    draft.user = null;
  });

const INITIAL_STATE: AuthState = {
  user: null,
  pending: true,
  error: null,
};

export const AuthContext = createContext<AuthState>(INITIAL_STATE);

const AuthProvider = (props: any) => {
  const [state_, dispatch] = useImmerReducer(reducer, INITIAL_STATE);
  const state = useMemoizedValue(state_);
  const location = useLocation();
  const url = `${location.pathname}${location.search}`

  const organizationSlug =
    useRouteMatch<{slug: string}>(`${rootRoutes.customer}`)?.params.slug || '';

  useEffect(() => {
    initializeUserManager({
      [AuthenticationQueryKeys.OrganizationSlug]: organizationSlug,
      // [AuthenticationQueryKeys.AuthSessionToken]: uuidv4(),
    });

    (async () => {
      const user = await getUser();
      log.debug('Initial user', user);

      userManager.events.addUserLoaded(async (updatedUser) => {
        dispatch(onUserLoaded(updatedUser));
      });

      userManager.events.addUserUnloaded(() => {
        dispatch(onUserUnloaded(undefined));
      });

      if (user && !user.expired) {
        dispatch(onUserLoaded(user));
      } else if (window.location.href.includes('#id_token')) {
        log.debug('Handle callback');
        try {
          await userManager.signinRedirectCallback();
        } catch (error) {
          log.info('Callback Error', error);
          (window as any).location = Env.appRoot;
        }
      } else {
        log.info('signinRedirect');
        dispatch(redirecting(undefined));
        userManager.signinRedirect({
          state: {
            url,
          },
        });
      }
    })();
  }, [organizationSlug, dispatch, url]);

  if (state.pending) {
    return (
      <DelayedLoadingContainer delayInMs={1000}>
        <Dimmer active inverted>
          <Loader indeterminate />
        </Dimmer>
      </DelayedLoadingContainer>
    );
  }

  if (!state.user && !state.pending) {
    return <div>Not Authenticated</div>;
  }

  return <AuthContext.Provider value={state} {...props} />;
};

function useUser(): User {
  const {user} = useContext(AuthContext);
  if (!user) {
    throw new Error(`useUser must be used within an authenticated app`);
  }
  return user;
}

export {AuthProvider, useUser};
