import css from '@emotion/css/macro';
import {cx} from 'emotion';
import React, {useCallback, useEffect, useState} from 'react';
import {faSignOut} from '@fortawesome/pro-regular-svg-icons';
import {Provider, useDispatch, useSelector} from 'react-redux';
import {Modal, Message, Button} from 'semantic-ui-react';
import {analtyics} from './analytics';
import {userManager} from './auth';
import {ChangePassword} from './auth/change-password';
import {useUser} from './auth/use-auth';
import {NonProductionWarning} from './components/non-production-warning';
import {Env} from './config/env-vars';
import {useSubscription} from './hooks/use-subscription';
import {LocationNotifier} from './routes/location-notifier';
import {payStarColors} from './styles/styled';

import {
  createStore,
  initializeContext,
  StoreState,
  setSessionExpired,
} from './store';
import {SkipNavLink} from '@reach/skip-nav';
import {CustomerRoutes} from './routes/customer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AuthenticationQueryKeys} from './api/generated/enums';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {useHasAuthorization} from './auth/authorization-helpers';
import {routes} from './routes';

export const CustomerApp = () => {
  const user = useUser();
  const store = createStore(user);
  const [modalOpen, setModalOpen] = useState(false);
  const match = useRouteMatch<{slug: string}>();
  const slug = match.params.slug;

  const history = useHistory();

  const isAdmin = useHasAuthorization({
    role: ['Global Admin', 'Organization Admin'],
  });

  useEffect(() => {
    analtyics.identify(`${user.id}`);
  }, [user.id]);

  useEffect(() => {
    userManager.clearStaleState();
  }, []);

  const onLocationChange = useCallback(
    location => analtyics.trackPage(location),
    []
  );

  return (
    <>
      <Provider store={store}>
        <OrganizationContextProvider>
          <LocationNotifier onChange={onLocationChange} />
          <SkipNavLink>Main Content</SkipNavLink>
          <div
            className={cx(
              'root-container',
              `${Env.name}-environment`,
              'admin-portal-styles root'
            )}
            css={styles}
          >
            <div className="root-container">
              <div className={cx('main-content', Env.name)}>
                <NonProductionWarning></NonProductionWarning>
                <div className="content">
                  <div className="sign-out">
                    <Button
                      primary
                      compact
                      onClick={() => {
                        if (isAdmin) {
                          history.push(routes.portal.accounts.listing);
                        } else {
                          if (slug === ':slug') {
                            userManager.signoutRedirect();
                          } else {
                            userManager.signoutRedirect({
                              extraQueryParams: {
                                [AuthenticationQueryKeys.OrganizationSlug]: slug,
                              },
                            });
                          }
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faSignOut} />{' '}
                      {isAdmin ? 'Accounts' : 'Sign Out'}
                    </Button>
                  </div>
                  <CustomerRoutes />
                </div>
              </div>
            </div>
          </div>
          <SessionExpiredModal />
        </OrganizationContextProvider>
      </Provider>

      <Modal size="small" open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Header>Change Password</Modal.Header>
        <Modal.Content>
          <ChangePassword
            onComplete={() => {
              setModalOpen(false);
            }}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

const OrganizationContextProvider = ({children}) => {
  const context = useSelector((state: StoreState) => state.global.context);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeContext());
  }, [dispatch]);

  if (context.error) {
    return <Message negative>Error loading context</Message>;
  }

  if (context.loading) {
    return null;
  }

  return children;
};

const SessionExpiredModal = () => {
  const dispatch = useDispatch();

  const sessionExpired = useSelector(
    (state: StoreState) => state.global.sessionExpired
  );

  useSubscription('session-expired', () => {
    dispatch(setSessionExpired());
    userManager.signoutRedirect();
  });

  return (
    <Modal open={sessionExpired}>
      <Modal.Header>Session Expired</Modal.Header>
    </Modal>
  );
};

const styles = css`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  .sign-out {
    position: absolute;
    display: block;
    right: 0;
    padding-top: 0.4rem;
    padding-right: 0.4rem;
  }

  .shadowed.ui.secondary.menu {
    box-shadow: rgb(87, 99, 105) 2px 0px 5px -2px;
  }

  .top-navigation.ui.secondary.menu {
    z-index: 3;
    margin: 0;
    padding: 0 25px 0 10px;

    @media only screen and (max-width: 500px) {
      padding: 0;
    }

    .ui.header {
      color: ${payStarColors.blue3};
      font-weight: normal;
    }

    .logo {
      height: 30px;
      margin-bottom: -5px;
    }

    .payment-disabled-icon {
      display: block;
      text-align: center;
    }

    .collect-payment {
      svg {
        width: 1.18em;
        margin: 0em 10px 0em 0.5em;
      }
    }

    .assigned-tickets {
      position: relative;

      .assigned-ticket-indicator {
        position: absolute;
        top: 0px;
        right: 0px;
        height: 1rem;
        width: 1rem;
        margin-top: -0.6rem !important;
        margin-right: -0.8rem !important;
        box-shadow: 0px 1px 1px 1px;
      }
    }
  }

  .root-container {
    flex: 1 0;
    display: flex;
    overflow: hidden;
    background-color: ${payStarColors.white2};
  }

  .user-icon {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    font-size: 1.3rem;
    background: ${payStarColors.blue4};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5rem;
  }

  .user-icon-initial {
    display: inline-block;
    width: 20px;
    text-align: center;
    padding-left: 2px;
    font-size: 0.9rem;
  }

  .main-content {
    flex: 1;
    overflow-x: auto;
    display: flex;
    position: relative;
    background: #fff;
    border: solid 1px #b4c5ce;
    box-shadow: rgb(87, 99, 105) -1px 2px 4px -3px;
    border-top: none;
    flex-direction: column;

    &.production {
      border-top: solid 4px ${payStarColors.primary.red};
    }

    > .content {
      flex: 1;
      overflow-y: auto;
      background-color: #f9f9f9;
      position: relative;
      height: 100%;
      background: linear-gradient(33deg, #f2f2f2 50%, #b2e2fb 50%);
    }
  }
`;
