import {includes, some} from 'lodash';
import React from 'react';
import {useUser} from './use-auth';
import {AllPermissions, Roles} from '../api/generated/enums';

type Permissions = typeof AllPermissions[number];
type PermissionParam = Permissions | Permissions[];
type RoleParam = Roles | Roles[];

type AuthorizationConditions =
  | {
      permission: PermissionParam;
      role?: RoleParam;
    }
  | {
      permission?: PermissionParam;
      role: RoleParam;
    }
  | {
      permission: PermissionParam;
      role: RoleParam;
    };

export type AuthorizationProps = AuthorizationConditions & {
  strict?: boolean;
};

export const useHasAuthorization = ({
  permission,
  role,
  strict,
}: AuthorizationProps) => {
  const user = useUser();

  const permissions = Array.isArray(permission) ? permission : [permission];
  const roles = Array.isArray(role) ? role : [role];

  const hasPermission = some(permissions, x => includes(user.permissions, x));
  const hasRole = roles.includes(user.role);

  const isGlobalAdmin = !strict && user.role === 'Global Admin';

  return isGlobalAdmin || hasPermission || hasRole;
};

export const AuthorizedComponent: React.FC<AuthorizationProps> = ({
  children,
  ...rest
}) => {
  const isAuthorized = useHasAuthorization({...rest});
  return <>{isAuthorized && children}</>;
};
