import produce from 'immer';
import {once} from 'lodash';
import thunkMiddleware, {ThunkMiddleware} from 'redux-thunk';
import actionCreatorFactory from 'typescript-fsa';
import {reducerWithInitialState} from 'typescript-fsa-reducers';
import {asyncFactory} from 'typescript-fsa-redux-thunk';
import {AsyncState, User} from './types/index';
import {parseJson} from './utils';
import {logger} from './utils/logger';
import {
  createStore as reduxCreateStore,
  combineReducers,
  compose,
  applyMiddleware,
  AnyAction,
  Store,
} from 'redux';

import {
  OrganizationsService,
  OrganizationContextDto,
  OrganizationSummaryDto,
  UsersService,
} from './api/generated';
import {Roles} from './api/generated/enums';

type GlobalState = {
  user: User;
  organizations: OrganizationSummaryDto[];
  context: AsyncState<OrganizationContext>;
  sessionExpired: boolean;
};

export type StoreState = {
  global: GlobalState;
};

export type OrganizationContext = {
  organizationId?: number;
  organization: OrganizationContextDto | null;
};

export let store: Store<StoreState>;

const composeEnhancers =
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;
const thunk: ThunkMiddleware<GlobalState, AnyAction> = thunkMiddleware;
const create = actionCreatorFactory('GLOBAL');
const createAsync = asyncFactory<GlobalState>(create);

const log = logger('meter-reading-system-store');
const savedContextKey = `last-selected-organization`;

export const setUser = create<User>('SET_USER');
export const setSessionExpired = create<void>('SET_SESSION_EXPIRED');

export const setContext = createAsync<number, OrganizationContext, Error>(
  'SET_CONTEXT',
  async (organizationId) => {
    const {data} = await OrganizationsService.getContextById({
      id: organizationId,
    });
    localStorage.setItem(savedContextKey, JSON.stringify({organizationId}));

    return {
      organizationId,
      organization: data,
    };
  }
);

type initializeContext = {
  context?: OrganizationContext;
  organizations: OrganizationSummaryDto[];
};

export const initializeContext = createAsync<void, initializeContext, Error>(
  'INITIALIZE_CONTEXT',
  async () => {
    const response = await OrganizationsService.getAll({
      pageSize: 0,
    });
    const currentUser = await await UsersService.getMe();

    // Idk how to avoid doing this because of ts. Talk to justin. (remove to find out why)
    if (!response || !response.data) {
      return {
        organizations: [] as OrganizationSummaryDto[],
      };
    }

    const lastKnownContext = parseJson<OrganizationContext>(
      localStorage.getItem(savedContextKey)
    );

    log.debug('lastKnownContext', lastKnownContext);

    let organizationId =
      currentUser.data?.organizationId ?? response.data.items[0].id;

    if (
      lastKnownContext &&
      lastKnownContext.organizationId &&
      currentUser.data?.role === Roles['Global Admin']
    ) {
      organizationId = lastKnownContext.organizationId;
    }

    const {data} = await OrganizationsService.getById({
      id: organizationId,
    });

    return {
      organizations: response.data.items,
      context: {
        organizationId,
        organization: data,
      },
    };
  }
);

export const fetchOrganizations = createAsync<
  void,
  OrganizationSummaryDto[],
  Error
>('FETCH_ORGANIZATIONS', async () => {
  const {data} = await OrganizationsService.getAll({
    pageSize: 0,
  });

  if (!data) {
    return [] as OrganizationSummaryDto[];
  }

  return data.items;
});

export const createStore = once((user: User) => {
  const initial: GlobalState = {
    user,
    organizations: [],
    context: {
      value: undefined,
      loading: true,
      error: undefined,
    },
    sessionExpired: false,
  };

  const globalStateReducer = reducerWithInitialState(initial)
    .case(setUser, (state, user) =>
      produce(state, (draft) => {
        draft.user = user;
      })
    )
    .case(setSessionExpired, (state) =>
      produce(state, (draft) => {
        draft.sessionExpired = true;
      })
    )
    .case(setContext.async.done, (state, response) =>
      produce(state, (draft) => {
        draft.context = {
          value: response.result,
          loading: false,
          error: undefined,
        };
      })
    )
    .case(initializeContext.async.started, (state) =>
      produce(state, (draft) => {
        draft.context.loading = true;
      })
    )
    .case(initializeContext.async.failed, (state, failure) =>
      produce(state, (draft) => {
        draft.context.loading = false;
        draft.context.error = failure.error;
      })
    )
    .case(initializeContext.async.done, (state, response) =>
      produce(state, (draft) => {
        const {context, organizations} = response.result;

        draft.context.value = context;
        draft.organizations = organizations;

        draft.context.loading = false;
        draft.context.error = undefined;
      })
    )
    .case(fetchOrganizations.async.done, (state, success) =>
      produce(state, (draft) => {
        draft.organizations = success.result;
        draft.context.value = {
          ...draft.context.value,
        } as OrganizationContext;
      })
    );

  const rootReducer = combineReducers({global: globalStateReducer});
  store = reduxCreateStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
});
