import { routes } from '.';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AccountCreate, AccountUpdate } from '../accounts/account-create-update';
import { AccountDashboard } from '../accounts/account-dashboard';
import { AccountListing } from '../accounts/account-listing';
import { MeterReadingsDashboard } from '../meter-readings/meter-readings-dashboard';
import { OrganizationListing } from '../organizations/organization-listing';
import { SettingDetails } from '../settings/setting-details';
import { SettingListing } from '../settings/setting-listing';
import { UserCreate, UserDetails } from '../users/user-create-update';
import { UserOrganizationListing } from '../users/user-listing';
import {
  MeterReadingCreate,
  MeterReadingDetails,
} from '../meter-readings/meter-reading-create-update';
import {
  OrganizationCreate,
  OrganizationDetails,
} from '../organizations/organization-create-update';
import {
  RateTableTierCreate,
  RateTableTierDetails,
} from '../rate-table-tiers/rate-table-tier-create-update';
import { RateTableListing } from '../rate-table/rate-table-listing';
import {
  RateTableCreate,
  RateTableUpdate,
} from '../rate-table/rate-table-create-update';
import { RateTableDashboard } from '../rate-table/rate-table-dashboard';
import { useHasAuthorization } from '../auth/authorization-helpers';

export const PortalRoutes = () => {
  const isAdmin = useHasAuthorization({
    role: ['Global Admin'],
  });

  return (
    <Switch>
      <Route path={routes.portal.dashboard} exact>
        <MeterReadingsDashboard />
      </Route>

      {isAdmin ? (
        <Route path={routes.portal.users.listing} exact>
          <UserOrganizationListing />
        </Route>
      ) : (
          <Route path={routes.portal.users.listing} exact>
            <UserOrganizationListing />
          </Route>
        )}

      <Route path={routes.portal.users.create} exact>
        <UserCreate />
      </Route>
      <Route path={routes.portal.users.detail}>
        <UserDetails />
      </Route>

      <Route path={routes.portal.organizations.listing} exact>
        <OrganizationListing />
      </Route>
      <Route path={routes.portal.organizations.create} exact>
        <OrganizationCreate />
      </Route>
      <Route path={routes.portal.organizations.detail} exact>
        <OrganizationDetails />
      </Route>

      <Route path={routes.portal.accounts.listing} exact>
        <AccountListing />
      </Route>
      <Route path={routes.portal.accounts.create} exact>
        <AccountCreate />
      </Route>
      <Route path={routes.portal.accounts.update} exact>
        <AccountUpdate />
      </Route>
      <Route path={routes.portal.accounts.dashboard} exact>
        <AccountDashboard />
      </Route>

      <Route path={routes.portal.rateTables.listing} exact>
        <RateTableListing />
      </Route>
      <Route path={routes.portal.rateTables.create} exact>
        <RateTableCreate />
      </Route>
      <Route path={routes.portal.rateTables.update} exact>
        <RateTableUpdate />
      </Route>
      <Route path={routes.portal.rateTables.dashboard} exact>
        <RateTableDashboard />
      </Route>

      <Route path={routes.portal.rateTableTiers.create} exact>
        <RateTableTierCreate />
      </Route>
      <Route path={routes.portal.rateTableTiers.detail} exact>
        <RateTableTierDetails />
      </Route>

      <Route path={routes.portal.meterReadings.create} exact>
        <MeterReadingCreate />
      </Route>
      <Route path={routes.portal.meterReadings.detail} exact>
        <MeterReadingDetails />
      </Route>
      <Route path={routes.portal.meterReadings.dashboard} exact>
        <MeterReadingsDashboard />
      </Route>

      <Route path={routes.portal.settings.listing} exact>
        <SettingListing />
      </Route>
      <Route path={routes.portal.settings.detail}>
        <SettingDetails />
      </Route>

      <Route>
        <Redirect to={routes.portal.dashboard} />
      </Route>
    </Switch>
  );
};
