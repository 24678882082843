import {faPencil, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {useAsyncRetry} from 'react-use';
import {Button, Modal} from 'semantic-ui-react';
import {AsyncStateContainer} from '../components/async-state-container';
import {} from '../api/generated/enums';
import {AccounteNotesService, AccountNoteDetailDto} from '../api/generated';
import {Form} from '../forms';
import {notifications} from '../utils/notification-service';
import {useOrganizationContext} from '../selectors';
import {css} from '@emotion/core';
import {DeleteButton} from '../components/confirm-delete-button';

type AccountNoteEditModal = {
  id: number;
  onSuccessReFetch: () => void;
};

export const AccountNoteEditModal = (props: AccountNoteEditModal) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accountNote, setAccountNote] = useState<AccountNoteDetailDto>();

  const id = props.id;

  useAsyncRetry(async () => {
    if (isModalOpen && id) {
      const {data} = await AccounteNotesService.getById({
        id: props.id,
      });
      setAccountNote(data || undefined);
      return data;
    }
  }, [id, isModalOpen, props.id]);

  const deleteAccountNote = async () => {
    const response = await AccounteNotesService.deleteById({id: props.id});
    if (response.hasErrors) {
      var errorMessage = response.errors.reduce((acc, item) => {
        return `${acc} \n ${item.errorMessage}`;
      }, '');

      notifications.error(errorMessage);
    } else {
      setIsModalOpen(false);
      notifications.success('Account note successfully deleted');
      props.onSuccessReFetch();
    }
  };

  const onSubmit = async values => {
    const response = await AccounteNotesService.update({id, body: values});
    if (response.hasErrors) {
      return response;
    }
    notifications.success('Account note Updated');
    props.onSuccessReFetch();
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        className="modal-create-update"
        css={styles}
        trigger={
          <Button
            className="clear"
            style={{marginLeft: 10}}
            basic
            icon
            aria-label={`Edit Account Note`}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faPencil} />
          </Button>
        }
        open={isModalOpen}
        closeOnEscape={isModalOpen}
        closeOnDimmerClick={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Modal.Content>
          <AsyncStateContainer {...accountNote} loading={false}>
            <Form
              initialValues={accountNote}
              onSubmit={onSubmit}
              render={() => (
                <>
                  <Form.Section title="Account Note Information">
                    <FormFields />
                  </Form.Section>
                  <div className="form-actions">
                    <Form.Button type="submit" primary>
                      Update Account
                    </Form.Button>
                    <Form.Button
                      secondary
                      type="button"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Close
                    </Form.Button>
                    <DeleteButton
                      onConfirm={deleteAccountNote}
                      tertiary
                      icon={false}
                    />
                  </div>
                </>
              )}
            />
          </AsyncStateContainer>
        </Modal.Content>
      </Modal>
    </div>
  );
};

type AccountNoteCreateModal = {
  accountId: number;
  onSuccessReFetch: () => void;
};

export const AccountNoteCreateModal = (props: AccountNoteCreateModal) => {
  const context = useOrganizationContext();
  const accountId = props.accountId;
  const organizationId = context.organizationId as number;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSubmit = async values => {
    values.organizationId = organizationId;
    values.accountId = accountId;
    const response = await AccounteNotesService.create({body: values});
    if (response.hasErrors) {
      return response;
    }
    setIsModalOpen(false);
    notifications.success('Account Note Created');
    props.onSuccessReFetch();
  };

  return (
    <div>
      <Modal
        className="modal-create-update"
        css={styles}
        trigger={
          <Button
            primary
            icon
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus} /> New Account Note
          </Button>
        }
        open={isModalOpen}
        closeOnEscape={isModalOpen}
        closeOnDimmerClick={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Modal.Content>
          <Form
            onSubmit={onSubmit}
            render={() => (
              <>
                <Form.Section title="Account Note Information">
                  <FormFields />
                </Form.Section>
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Create Account Note
                  </Form.Button>
                  <Form.Button
                    secondary
                    type="button"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Close
                  </Form.Button>
                </div>
              </>
            )}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

const FormFields = () => {
  return (
    <>
      <Form.TextArea fieldName="note" fieldLabel="Note" />
      <Form.DatePicker fieldName="noteDate" fieldLabel="Note Date" />
    </>
  );
};

const styles = css`
  &.modal-create-update {
    position: fixed;
    top: 25%;
  }
`;
