import {faDownload} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useMemo, useCallback, useState} from 'react';
import {Button} from 'semantic-ui-react';
import {MeterReadingsService, MeterReadingSummaryDto} from '../api/generated';
import {BasicPage} from '../basic-page';
import {routes, buildPath} from '../routes';
import {SingleDatePicker} from 'react-dates';

import {
  PagedDataTableConfig,
  usePagedDataTable,
  renderViewButton,
  renderEditButton,
} from '../hooks/use-paged-data-table';
import {useOrganizationContext} from '../selectors';
import {AdvancedPagedRequest} from '../types';
import moment, {Moment} from 'moment';
import {useAsyncFn} from 'react-use';
import {Flex} from '../components/flex';
import {Tooltip} from '../components/tooltip';

type FetchParams = Parameters<
  typeof MeterReadingsService.getAllByOrganizationId
>['0'];

export const MeterReadingsDashboard = () => {
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const [startDate, setStartDate] = useState<Moment | null>(
    moment().utc().startOf('day').add(-1, 'M')
  );
  const [endDate, setEndDate] = useState<Moment | null>(
    moment().utc().endOf('day')
  );

  const additionalParams = useMemo<FetchParams>(
    () => ({
      organizationId: organizationId,
      startDate: startDate == null ? undefined : startDate.format(),
      endDate: endDate == null ? undefined : endDate.format(),
    }),
    [organizationId, startDate, endDate]
  );

  const [startFocused, setStartFocused] = useState(false);
  const [endFocused, setEndFocused] = useState(false);

  const fetchMeterReadings = useCallback(
    (x: AdvancedPagedRequest<MeterReadingSummaryDto>) =>
      MeterReadingsService.getAllByOrganizationId({
        ...additionalParams,
        ...x,
      } as FetchParams),
    [additionalParams]
  );

  const [
    fetchMeterReadingsPdfState,
    fetchMeterReadingsPdf,
  ] = useAsyncFn(async () => {
    var response = await MeterReadingsService.getExcelReport({
      organizationId: organizationId,
      startDate:
        startDate === null ? undefined : startDate.format('MM/DD/YYYY'),
      endDate: endDate === null ? undefined : endDate.format('MM/DD/YYYY'),
    });

    if (response.hasErrors || response.data == null) {
      return response;
    }

    const bytes = Uint8Array.from(atob(response.data.data), (c) =>
      c.charCodeAt(0)
    );

    var blob = new Blob([bytes], {type: 'application/vnd.ms-excel'});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = response.data.fileName;
    link.click();
  }, [startDate, endDate, organizationId]);

  const pagedDataTable = usePagedDataTable(fetchMeterReadings, TableConfig, {
    actions: (
      <>
        <Flex.Row>
          <Flex.Box>
            <span>Start Date: </span>
            <SingleDatePicker
              id={'startDate'}
              date={startDate}
              onDateChange={(x) =>
                setStartDate(x?.utc().startOf('day') || null)
              }
              focused={startFocused}
              onFocusChange={() => {
                setStartFocused(true);
              }}
              onClose={() => setStartFocused(false)}
              numberOfMonths={1}
              isOutsideRange={() => false}
            />
          </Flex.Box>
          <Flex.Box>
            <span style={{paddingRight: '20px'}}></span>
          </Flex.Box>
          <Flex.Box>
            <span>End Date: </span>
            <SingleDatePicker
              id={'endDate'}
              date={endDate}
              onDateChange={(x) => {
                setEndDate(x?.utc().endOf('day') || null);
              }}
              focused={endFocused}
              onFocusChange={() => setEndFocused(true)}
              onClose={() => setEndFocused(false)}
              numberOfMonths={1}
              isOutsideRange={() => false}
            />
          </Flex.Box>
          <Flex.Box>
            <span style={{paddingRight: '20px'}}></span>
          </Flex.Box>
          <Flex.Box>
            <Tooltip label="Download Meter Readings Report">
              <Button
                size={'large'}
                type="button"
                loading={fetchMeterReadingsPdfState.loading}
                primary
                onClick={async () => await fetchMeterReadingsPdf()}
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            </Tooltip>
          </Flex.Box>
        </Flex.Row>
      </>
    ),
  });

  return <BasicPage title="Meter Readings">{pagedDataTable}</BasicPage>;
};

const TableConfig = PagedDataTableConfig(
  MeterReadingsService.getAllByOrganizationId,
  {
    columns: [
      {
        header: '',
        render: (item) => (
          <>
            {renderViewButton({
              item,
              descriptor: 'account',
              route: buildPath(routes.portal.accounts.dashboard, {
                id: item.accountId,
              }),
            })}
            {renderEditButton({
              item,
              descriptor: 'meter reading',
              route: buildPath(routes.portal.meterReadings.detail, {
                id: item.id,
              }),
            })}
          </>
        ),
        cellProps: {
          collapsing: true,
        },
      },
      {
        header: 'Submit Date',
        sortable: 'createdDate',
        render: (item) => `${moment(item.createdDate).format('MM/DD/YYYY')}`,
      },
      {
        header: 'Reading Date',
        sortable: 'readingDate',
        render: (item) => `${moment(item.readingDate).format('MM/DD/YYYY')}`,
      },
      {
        header: 'Acct #',
        column: 'accountNumber',
        sortable: 'accountNumber',
      },
      {
        header: 'Name',
        column: 'accountName',
        sortable: 'accountName',
      },
      {
        header: 'Reading Amount',
        column: 'readingAmount',
        sortable: 'readingAmount',
      },
      {
        header: 'Units Used',
        sortable: 'unitsUsed',
        render: (item) => {
          return (
            <>
              {item.unitsUsed} {item.unitType}
            </>
          );
        },
      },
      {
        header: '$ Due',
        column: 'amountDue',
      },
      {
        header: '$ Paid',
        render: (item) => {
          return (
            <>
              {item.paymentReferenceNumber ? (
                <a href={item.paymentReferenceNumberLink}>
                  {item.amountReceived}
                </a>
              ) : (
                item.amountReceived
              )}
            </>
          );
        },
      },
      {
        header: 'Comments',
        column: 'comments',
      },
    ],
    searchFieldNames: ['accountNumber', 'accountName', 'readingAmount'],
    defaultSort: {
      column: 'createdDate',
      direction: 'DESC',
    },
  }
);
