import css from '@emotion/css/macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SkipNavContent, SkipNavLink} from '@reach/skip-nav';
import {cx} from 'emotion';
import nameInitials from 'name-initials';
import React, {useCallback, useEffect, useState} from 'react';
import {Provider, useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {
  Button,
  Divider,
  Dropdown,
  Menu,
  Modal,
  Responsive,
} from 'semantic-ui-react';
import {Image, Message} from 'semantic-ui-react';
import {analtyics} from './analytics';
import e3logo from './assets/e3-logo.png';
import {userManager} from './auth';
import {ChangePassword} from './auth/change-password';
import {useUser} from './auth/use-auth';
import {NavigationLink} from './components/navigation-link';
import {NonProductionWarning} from './components/non-production-warning';
import {Env} from './config/env-vars';
import {ContextSelector} from './context-selector';
import {useSubscription} from './hooks/use-subscription';
import {PrimaryNavigation} from './primary-navigation';
import {routes, buildPath} from './routes';
import {LocationNotifier} from './routes/location-notifier';
import {payStarColors} from './styles/styled';

import {
  createStore,
  initializeContext,
  StoreState,
  setSessionExpired,
} from './store';
import {
  faCog,
  faGlobe,
  faTable,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import {PortalRoutes} from './routes/portal';
import {useHasAuthorization} from './auth/authorization-helpers';
import {useOrganizationContext} from './selectors';

export const PortalApp = () => {
  const user = useUser();

  const store = createStore(user);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    analtyics.identify(`${user.id}`);
  }, [user.id]);

  useEffect(() => {
    userManager.clearStaleState();
  }, []);

  const onLocationChange = useCallback(
    (location) => analtyics.trackPage(location),
    []
  );

  return (
    <>
      <Provider store={store}>
        <OrganizationContextProvider>
          <LocationNotifier onChange={onLocationChange} />
          <SkipNavLink>Main Content</SkipNavLink>
          <div
            className={cx(
              'root-container',
              `${Env.name}-environment`,
              'admin-portal-styles root'
            )}
            css={styles}
          >
            <div className="root-container">
              <div className={cx('main-content', Env.name)}>
                <NonProductionWarning></NonProductionWarning>
                <Menu secondary className="top-navigation shadowed">
                  <Menu.Item as={Link} to={routes.portal.dashboard}>
                    <div style={{fontSize: '1.3rem'}}>Submit My Reading</div>
                  </Menu.Item>
                  <ContextSelector />
                  <Responsive minWidth={1200} as={React.Fragment}>
                    <PrimaryNavigation user={user} />
                  </Responsive>
                  <Menu.Menu position="right">
                    <LookupAccount />
                    <Responsive minWidth={768} as={React.Fragment}>
                      <AdminMenu />
                    </Responsive>
                    <Dropdown
                      item
                      trigger={
                        <span className="user-icon">
                          <span className="user-icon-initial" title={user.name}>
                            {nameInitials(user.name)}
                          </span>
                        </span>
                      }
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setModalOpen(true)}>
                          Change Password
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            userManager.signoutRedirect();
                          }}
                        >
                          Sign Out
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu.Menu>
                </Menu>
                <div className="content">
                  <SkipNavContent></SkipNavContent>
                  <PortalRoutes />
                  <Image
                    src={e3logo}
                    size="small"
                    alt="Elevator3 logo"
                    className="logo"
                  />
                </div>
              </div>
            </div>
          </div>
          <SessionExpiredModal />
        </OrganizationContextProvider>
      </Provider>

      <Modal size="small" open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Header>Change Password</Modal.Header>
        <Modal.Content>
          <ChangePassword
            onComplete={() => {
              setModalOpen(false);
            }}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

const LookupAccount = () => {
  const context = useOrganizationContext();
  const slug = context.organization.slug;

  return (
    <Responsive minWidth={768} as={React.Fragment}>
      <Menu.Item>
        <Button
          className="better-border"
          basic
          icon
          fluid
          primary
          as={Link}
          to={buildPath(routes.customer.meterReadingInput, {
            slug,
          })}
          aria-label="Add Meter Reading"
        >
          Add Meter Reading
        </Button>
      </Menu.Item>
    </Responsive>
  );
};

const AdminMenu = () => {
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const isAdmin = useHasAuthorization({
    role: ['Global Admin'],
  });

  const isAdminOrOrganzation = useHasAuthorization({
    role: ['Global Admin', 'Organization Admin'],
  });

  const url = buildPath(routes.portal.organizations.detail, {
    id: organizationId,
  });

  return (
    <Dropdown item trigger={<FontAwesomeIcon icon={faCog} />} icon={null}>
      <Dropdown.Menu direction="left">
        <NavigationLink
          to={isAdmin ? routes.portal.organizations.listing : url}
        >
          <FontAwesomeIcon icon={faGlobe} /> Organization
        </NavigationLink>

        <Divider />

        <NavigationLink to={routes.portal.rateTables.listing}>
          <FontAwesomeIcon icon={faTable} /> Rate Tables
        </NavigationLink>

        {isAdminOrOrganzation && (
          <>
            <Divider />
            <NavigationLink to={routes.portal.users.listing}>
              <FontAwesomeIcon icon={faUsers} /> Users
            </NavigationLink>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const OrganizationContextProvider = ({children}) => {
  const context = useSelector((state: StoreState) => state.global.context);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeContext());
  }, [dispatch]);

  if (context.error) {
    return <Message negative>Error loading context</Message>;
  }

  if (context.loading) {
    return null;
  }

  return children;
};

const SessionExpiredModal = () => {
  const dispatch = useDispatch();

  const sessionExpired = useSelector(
    (state: StoreState) => state.global.sessionExpired
  );

  useSubscription('session-expired', () => {
    dispatch(setSessionExpired());
    userManager.signoutRedirect();
  });

  return (
    <Modal open={sessionExpired}>
      <Modal.Header>Session Expired</Modal.Header>
    </Modal>
  );
};

const styles = css`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  .shadowed.ui.secondary.menu {
    box-shadow: rgb(87, 99, 105) 2px 0px 5px -2px;
  }

  .top-navigation.ui.secondary.menu {
    z-index: 3;
    margin: 0;
    padding: 0 25px 0 10px;

    @media only screen and (max-width: 500px) {
      padding: 0;
    }

    .ui.header {
      color: ${payStarColors.blue3};
      font-weight: normal;
    }

    .logo {
      height: 30px;
      margin-bottom: -5px;
    }

    .payment-disabled-icon {
      display: block;
      text-align: center;
    }

    .collect-payment {
      svg {
        width: 1.18em;
        margin: 0em 10px 0em 0.5em;
      }
    }

    .assigned-tickets {
      position: relative;

      .assigned-ticket-indicator {
        position: absolute;
        top: 0px;
        right: 0px;
        height: 1rem;
        width: 1rem;
        margin-top: -0.6rem !important;
        margin-right: -0.8rem !important;
        box-shadow: 0px 1px 1px 1px;
      }
    }
  }

  .root-container {
    flex: 1 0;
    display: flex;
    overflow: hidden;
    background-color: ${payStarColors.white2};
  }

  .user-icon {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    font-size: 1.3rem;
    background: ${payStarColors.blue4};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5rem;
  }

  .user-icon-initial {
    display: inline-block;
    width: 20px;
    text-align: center;
    padding-left: 2px;
    font-size: 0.9rem;
  }

  .main-content {
    flex: 1;
    overflow-x: auto;
    display: flex;
    position: relative;
    background: #fff;
    border: solid 1px #b4c5ce;
    box-shadow: rgb(87, 99, 105) -1px 2px 4px -3px;
    border-top: none;
    flex-direction: column;

    &.production {
      border-top: solid 4px ${payStarColors.primary.red};
    }

    > .content {
      flex: 1;
      overflow-y: auto;
      background-color: #f9f9f9;
      position: relative;
    }

    .logo {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 25px;
    }
  }

  .better-border.ui.button.basic:not(.action-button) {
    box-shadow: none !important;
    border: solid 1px;
    border-color: #ccc;
    box-shadow: none;
    background-color: transparent;
  }

  .better-border.ui.button.basic:not(.action-button):hover {
    border: solid 1px;
    box-shadow: none;
    background-color: transparent;
  }
`;
