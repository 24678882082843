import React from 'react';
import meterReadingLogo from '../images/meter-reader-balanced-black.png';

import {customerStyles} from './customer-styles';
import {useQuery} from '../hooks/use-query';

export const PaymentSuccessful = React.memo(() => {
  const query = useQuery();
  const accountName = query.get('accountName');
  const accountNumber = query.get('accountNumber');
  const paymentAmount = query.get('paymentAmount');

  return (
    <div css={customerStyles}>
      <div className="center-content-box">
        <div className="meter-reading-main-content">
          <div className="circle-content">
            <img
              className="meter-image"
              alt="meter reader logo"
              src={meterReadingLogo}
              height="250"
              width="250"
            />
          </div>
          {(accountName || accountNumber) && (
            <>
              <div className="customer-header-content">
                {accountName && (
                  <span className="customer-name">{accountName}</span>
                )}
                {accountNumber && (
                  <span className="account-information muted">
                    <span className="small-text">Account Number: </span>
                    {accountNumber}
                  </span>
                )}
              </div>

              <div className="divider" />
            </>
          )}

          <div className="amount-due-container">
            <div className="medium-text">
              Payment {paymentAmount ? `of ${paymentAmount}` : ''} accepted.
            </div>
            <br />
            <span className="title">Thanks!</span>
          </div>
        </div>
      </div>
    </div>
  );
});
