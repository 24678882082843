import React, {useState} from 'react';
import {Button, Message, Modal} from 'semantic-ui-react';
import {Form} from '../forms';
import {} from '../api/generated/enums';
import {css} from '@emotion/core';
import {IntegrationsService} from '../api/generated';

type PaymentConfirmModal = {
  accountId: number;
  defaultAmount: number | undefined;
  paymentMade: boolean;
};

export const PaymentConfirmModal = (props: PaymentConfirmModal) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onSubmit = async (values) => {
    const response = await IntegrationsService.postPaymentToPayStar({
      body: {
        accountId: props.accountId as number,
        freeFormAmount: values.freeFormAmount ?? 0,
      } as any,
    });

    if (response.hasErrors) {
      return response;
    }

    window.location.assign(response.data?.paymentLogInLink as string);
  };

  return (
    <Modal
      css={styles}
      trigger={
        <Button
          className="pay-with-paystar"
          primary
          fluid
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          {props.paymentMade ? 'Make an Additional Payment' : 'Pay Now'}
        </Button>
      }
      open={isModalOpen}
      closeOnEscape={isModalOpen}
      closeOnDimmerClick={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      closeIcon
    >
      <Modal.Content>
        <Form
          onSubmit={onSubmit}
          initialValues={{freeFormAmount: props.defaultAmount}}
          render={() => (
            <>
              <Form.Section title="Select Payment Amount">
                <Message
                  warning
                  header="Notice"
                  content="Please add any additional late fees or outstanding balances to your payment."
                />
                <FormFields />
              </Form.Section>

              <Form.Button type="submit" primary>
                Pay Now
              </Form.Button>
            </>
          )}
        />
      </Modal.Content>
    </Modal>
  );
};

const FormFields = () => {
  return (
    <Form.InputCurrency
      fieldName="freeFormAmount"
      fieldLabel="How much would you like to pay?"
    />
  );
};

const styles = css`
  &.modal-notes {
    position: fixed;
    top: 80px;
    bottom: 1;
  }

  &.menu-item {
    flex-direction: row;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  &.modal-create-header {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  &.modal-create-header-item {
    flex-direction: row;
    display: flex;
    margin-left: auto;
  }
`;
