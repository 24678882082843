import React, {useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {
  MeterReadingsService,
  AccountsService,
  MeterReadingDetailDto,
} from '../api/generated';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {DeleteButton} from '../components/confirm-delete-button';
import {Form} from '../forms';
import {useNotification} from '../hooks/use-notifications';
import {buildPath, routes} from '../routes';
import {useOrganizationContext} from '../selectors';
import {MeterReadingCalculatorModal} from './meter-reading-calculate-modal';
import moment from 'moment';
import {isInclusivelyAfterDay} from 'react-dates';

export const MeterReadingCreate = () => {
  const history = useHistory();
  const notifications = useNotification();
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;
  const match = useRouteMatch<{accountId: string}>();
  const accountId = Number(match.params.accountId);

  const accountDashboardUrl = buildPath(routes.portal.accounts.dashboard, {
    id: accountId,
  });

  const [amountDue, setAmountDue] = useState<number>(0);
  const [unitsUsed, setUnitsUsed] = useState<number>(0);
  const [readingAmount, setReadingAmount] = useState<number>(0);

  const fetchAccount = useAsync(async () => {
    const {data} = await AccountsService.getById({id: accountId});

    if (data === null) return null;

    return data;
  }, [accountId]);

  const account = fetchAccount.value || undefined;

  const breadcrumbs = [
    {title: 'Meter Readings', url: accountDashboardUrl},
    {title: 'Create'},
  ];

  const onSubmit = async (values) => {
    values.accountId = accountId;
    values.organizationId = organizationId;

    const response = await MeterReadingsService.create({body: values});
    if (response.hasErrors) {
      return response;
    }

    notifications.success('Meter Reading Created');
    history.goBack();
  };

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchAccount}>
        <Form.Container>
          <Form
            onSubmit={onSubmit}
            render={({values}) => (
              <>
                <Form.Section title="Meter Reading Information">
                  <FormFields
                    isCreate={true}
                    amountDue={amountDue}
                    readingAmount={readingAmount}
                    unitsUsed={unitsUsed}
                    unitType={account?.unitType || 'Units'}
                  />
                </Form.Section>
                <MeterReadingCalculatorModal
                  endMeterReadingAmount={values.readingAmount}
                  rateTableId={account?.rateTableId ?? 0}
                  applyOnCalculate={(amountDue, readingAmount, unitsUsed) => {
                    setAmountDue(amountDue);
                    setUnitsUsed(unitsUsed);
                    setReadingAmount(readingAmount);
                  }}
                />
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Create Meter Reading
                  </Form.Button>
                  <Form.Button
                    type="button"
                    secondary
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Form.Button>
                </div>
              </>
            )}
          />
        </Form.Container>
      </AsyncStateContainer>
    </BasicPage>
  );
};

export const MeterReadingDetails = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const notifications = useNotification();

  const [
    meterReading,
    setMeterReading,
  ] = useState<MeterReadingDetailDto | null>();

  const fetchMeterReading = useAsync(async () => {
    const {data} = await MeterReadingsService.getById({
      id,
    });
    setMeterReading(data);
    return data;
  }, [id]);

  const fetchAccount = useAsync(async () => {
    if (meterReading?.accountId) {
      const {data} = await AccountsService.getById({
        id: meterReading?.accountId ?? 0,
      });
      return data;
    }
  }, [meterReading]);

  const account = fetchAccount.value || undefined;

  const [amountDue, setAmountDue] = useState<number>(
    meterReading?.amountDue.value ?? 0
  );
  const [unitsUsed, setUnitsUsed] = useState<number>(
    meterReading?.unitsUsed ?? 0
  );
  const [readingAmount, setReadingAmount] = useState<number>(
    meterReading?.readingAmount ?? 0
  );

  const breadcrumbs = [
    {title: 'Meter Readings', url: routes.portal.accounts.listing},
  ];

  const onSubmit = async (values) => {
    const response = await MeterReadingsService.update({id, body: values});
    if (response.hasErrors) {
      return response;
    }
    notifications.success('Meter Reading Updated');
    history.goBack();
  };

  const [deleteMeterReadingState, deleteMeterReading] = useAsyncFn(async () => {
    const response = await MeterReadingsService.deleteById({id});
    if (response.hasErrors) {
      var errorMessage = response.errors.reduce((acc, item) => {
        return `${acc} \n ${item.errorMessage}`;
      }, '');

      notifications.error(errorMessage);
    } else {
      notifications.success('Meter Reading successfully deleted');
      history.push(routes.portal.accounts.listing, {id: id});
    }
  });

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchMeterReading}>
        <AsyncStateContainer {...fetchAccount}>
          {meterReading && (
            <Form.Container>
              <Form
                initialValues={meterReading}
                onSubmit={onSubmit}
                render={({values}) => (
                  <>
                    <Form.Section title="Meter Reading Information">
                      <FormFields
                        readingAmount={readingAmount}
                        amountDue={amountDue}
                        unitsUsed={unitsUsed}
                        unitType={meterReading.unitType}
                      />
                    </Form.Section>
                    <MeterReadingCalculatorModal
                      endMeterReadingAmount={values.readingAmount}
                      rateTableId={account?.rateTableId ?? 0}
                      applyOnCalculate={(
                        amountDue,
                        readingAmount,
                        unitsUsed
                      ) => {
                        setAmountDue(amountDue);
                        values.amountDue.value = amountDue;
                        setUnitsUsed(unitsUsed);
                        values.unitsUsed = unitsUsed;
                        setReadingAmount(readingAmount);
                        values.readingAmount = readingAmount;
                      }}
                    />
                    <div className="form-actions">
                      <Form.Button type="submit" primary>
                        Update Meter Reading
                      </Form.Button>
                      <Form.Button
                        type="button"
                        secondary
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Cancel
                      </Form.Button>

                      <DeleteButton
                        onConfirm={deleteMeterReading}
                        loading={deleteMeterReadingState.loading}
                        tertiary
                        icon={false}
                      />
                    </div>
                  </>
                )}
              />
            </Form.Container>
          )}
        </AsyncStateContainer>
      </AsyncStateContainer>
    </BasicPage>
  );
};

type FormFields = {
  isCreate?: boolean;
  amountDue: number;
  unitType: string;
  readingAmount: number;
  unitsUsed: number;
};

const FormFields = (props: FormFields) => {
  return (
    <>
      <Form.DatePicker
        fieldName="readingDate"
        fieldLabel="Reading Date"
        isOutsideRange={(date) =>
          isInclusivelyAfterDay(date, moment().add(1, 'day'))
        }
      />
      <Form.Row proportions={[1, 1, 1, 1]}>
        <Form.InputMasked
          type="number"
          fieldName="readingAmount"
          fieldLabel="Reading Amount"
          defaultValue={props.readingAmount}
        />
        <Form.InputMasked
          type="number"
          fieldName="unitsUsed"
          fieldLabel={`${props.unitType} Used`}
          defaultValue={props.unitsUsed}
        />
        <Form.InputCurrency
          fieldName="amountDue.value"
          fieldLabel="Amount Due"
          defaultValue={props.amountDue}
        />
        <Form.InputCurrency
          fieldName="amountReceived.value"
          fieldLabel="Amount Received"
        />
      </Form.Row>

      <Form.TextArea fieldName="comments" fieldLabel="Comments" />
    </>
  );
};
