import produce, {Draft} from 'immer';
import {useReducer, useMemo} from 'react';

type ActionCreator<State, Payload> = (
  payload: Payload
) => {type: string; payload: Payload};
type CreatedAction<State, Payload> = ActionCreator<State, Payload> & AnyAction;
type Handler<State, Payload> = (state: Draft<State>, payload: Payload) => void;
type Handlers<State> = {
  [actionType: string]: Handler<State, any>;
};

interface AnyAction {
  type: any;
}

interface Reducer<State> {
  initialState: State;
  when: <Payload>(
    action: CreatedAction<State, Payload>,
    handler: Handler<State, Payload>
  ) => Reducer<State>;
  build: () => Handlers<State>;
}
export const actionCreatorFactory = <State>() => <Payload>(key: string) => {
  const cb = (payload: Payload) => ({type: key, payload});
  ((cb as unknown) as AnyAction).type = key;
  return (cb as unknown) as CreatedAction<State, Payload>;
};

export const createReducer = <State>(initialState?: State) => {
  const reducer: Reducer<State> = {
    initialState,
  } as Reducer<State>;
  const handlers: Handlers<State> = {};

  reducer.when = <Payload>(
    action: CreatedAction<State, Payload>,
    handler: Handler<State, Payload>
  ) => {
    handlers[action.type] = handler;
    return reducer;
  };

  reducer.build = () => {
    const buildReducer = Object.keys(handlers).reduce((acc, key) => {
      acc[key] = (state, payload) =>
        produce(state, draft => {
          handlers[key](draft, payload);
        });
      return acc;
    }, {});

    return buildReducer;
  };

  return reducer;
};

export const useImmerReducer = <State>(
  reducer: Reducer<State>,
  initialState?: State
) => {
  const handlers = useMemo(() => reducer.build(), [reducer]);
  return useReducer((state, action) => {
    if (handlers[action.type]) {
      return (handlers[action.type](state, action.payload) as unknown) as State;
    }
    return state as State;
  }, initialState ?? reducer.initialState);
};
