import React from 'react';
import {useAsyncFn} from 'react-use';
import {Button, Message} from 'semantic-ui-react';
import {notifications} from '../utils/notification-service';
import {useHasAuthorization} from '../auth/authorization-helpers';
import {
  MeterReadingsService,
  AccountForCustomer,
  IntegrationsService,
  MeterReadingSummaryDto,
  RateTableDetailDto,
} from '../api/generated';
import {userManager} from '../auth';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {AuthenticationQueryKeys} from '../api/generated/enums';
import {routes} from '../routes';
import {PaymentConfirmModal} from './payment-confirm-modal';

type MeterReadingSubmitted = {
  account: AccountForCustomer;
  rateTable: RateTableDetailDto;
  meterReading: MeterReadingSummaryDto;
  daysSinceLastReading: number;
};

export const MeterReadingSubmitted = (props: MeterReadingSubmitted) => {
  const {account, meterReading, rateTable, daysSinceLastReading} = props;

  const history = useHistory();
  const match = useRouteMatch<{slug: string}>();
  const slug = match.params.slug;
  const allowMultiplePayments = props.account.allowMultiplePayments;

  const paymentMade =
    Number(
      (
        Number(meterReading?.amountReceived.replace('$', '') ?? 0) * 100
      ).toFixed(2)
    ) > 0;
  const amountDue = Number(
    (Number(meterReading?.amountDue.replace('$', '') ?? 0) * 100).toFixed(2)
  );

  const isAdmin = useHasAuthorization({
    role: ['Global Admin', 'Organization Admin', 'User'],
  });

  const [handlePayWithPayStarState, handlePayWithPayStar] = useAsyncFn(
    async () => {
      const response = await IntegrationsService.postPaymentToPayStar({
        body: {
          accountId: (account.id as unknown) as number,
        } as any,
      });

      if (!response.data) {
        return;
      }

      if (response.hasErrors) {
        response.errors.map((x) => notifications.error(x.errorMessage));
      } else {
        window.location.assign(response.data.paymentLogInLink);
      }
    }
  );

  const handleGoBack = async () => {
    var confirmGoBack = window.confirm(
      'This will delete the meter entry you just submitted. You will need to resubmit a meter reading if you go back.'
    );

    if (confirmGoBack) {
      var response = await MeterReadingsService.deleteByIdForCustomerPortal({
        id: meterReading?.id ?? 0,
      });

      if (response.hasErrors) {
        response.errors.forEach((x) => notifications.error(x.errorMessage));
      } else {
        window.location.reload();
      }
    }
  };

  return (
    <>
      <div className="customer-header-content">
        <span className="customer-name">{account.name}</span>
        <span className="account-information muted">
          <span className="small-text">Account Number: </span>
          {account.accountNumber}
        </span>
      </div>

      {daysSinceLastReading > 1 && (
        <div className="last-reading-info">
          You submitted a reading {daysSinceLastReading} days ago.
          <br />
          You can submit a new reading in {10 - daysSinceLastReading} days.
        </div>
      )}

      <div className="divider" />

      {!rateTable.manuallyCalculated && (
        <div className="amount-due-container">
          <span className="amount-due-title medium-text">
            Amount due for this billing cycle:
          </span>
          <span className="amount-due-measure large-text bold">
            {meterReading.amountDue}
          </span>
        </div>
      )}
      <div className="amount-due-container">
        <span className="amount-due-title medium-text">
          {meterReading.unitType} used:
        </span>
        <span className="amount-due-measure large-text bold">
          {meterReading.unitsUsed}
        </span>
      </div>

      {daysSinceLastReading < 2 && !paymentMade && (
        <>
          <div style={{paddingBottom: 10, textAlign: 'center'}}>
            Is this not correct?{' '}
            <span className="go-back-span" onClick={handleGoBack}>
              Click here
            </span>{' '}
            to resubmit.
          </div>
        </>
      )}

      {rateTable.manuallyCalculated && (
        <div>
          <p style={{margin: '20px 50px', textAlign: 'center'}}>
            Thank you for submitting your meter reading! Please calculate the
            amount due based on this usage.
          </p>
        </div>
      )}

      <div className="payment-options-container">
        {paymentMade && (
          <Message info style={{marginTop: 10}}>
            We received your payment of <b>{meterReading.amountReceived}</b>.
            Thank you!
          </Message>
        )}
        {!paymentMade ? (
          account.allowFreeFormPayments ? (
            <PaymentConfirmModal
              accountId={Number(props.account.id)}
              defaultAmount={amountDue}
              paymentMade={paymentMade}
            />
          ) : (
            <Button
              className="pay-with-paystar"
              primary
              fluid
              onClick={handlePayWithPayStar}
              loading={handlePayWithPayStarState.loading}
            >
              Pay Now
            </Button>
          )
        ) : (
          allowMultiplePayments && (
            <PaymentConfirmModal
              accountId={Number(props.account.id)}
              defaultAmount={amountDue}
              paymentMade={paymentMade}
            />
          )
        )}
        <Button
          className="return-button"
          secondary
          fluid
          onClick={() => {
            if (isAdmin) {
              history.push(routes.portal.accounts.listing);
            } else {
              userManager.signoutRedirect({
                extraQueryParams: {
                  [AuthenticationQueryKeys.OrganizationSlug]: slug,
                },
              });
            }
          }}
        >
          {isAdmin ? 'Return to Accounts' : 'Exit and Pay Offline'}
        </Button>
      </div>
    </>
  );
};
