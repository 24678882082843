import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useCallback, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {RateTableTiersService, RateTableTierSummaryDto} from '../api/generated';
import {BasicPage} from '../basic-page';
import {routes, buildPath} from '../routes';
import {
  PagedDataTableConfig,
  usePagedDataTable,
  renderEditButton,
} from '../hooks/use-paged-data-table';
import {AdvancedPagedRequest} from '../types';
import {useOrganizationContext} from '../selectors';

type FetchParams = Parameters<
  typeof RateTableTiersService.getAllByRateTable
>['0'];

type RateTableTierListing = {
  getRateTableId: () => number;
};

export const RateTableTierListing = (props: RateTableTierListing) => {
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const additionalParams = useMemo<FetchParams>(() => {
    return {
      rateTableId: props.getRateTableId(),
      organizationId: organizationId,
    };
  }, [props, organizationId]);

  const fetchRateTableTiers = useCallback(
    (x: AdvancedPagedRequest<RateTableTierSummaryDto>) =>
      RateTableTiersService.getAllByRateTable({
        ...additionalParams,
        ...x,
      } as FetchParams),
    [additionalParams]
  );

  const createNewRateTableTierUrl = buildPath(
    routes.portal.rateTableTiers.create,
    {
      rateTableId: props.getRateTableId(),
    }
  );

  const pagedDataTable = usePagedDataTable(fetchRateTableTiers, TableConfig, {
    actions: (
      <Button as={Link} to={createNewRateTableTierUrl} primary>
        <FontAwesomeIcon icon={faPlus} /> New Tier
      </Button>
    ),
  });

  return <BasicPage title="Rate Tiers">{pagedDataTable}</BasicPage>;
};

const TableConfig = PagedDataTableConfig(
  RateTableTiersService.getAllByRateTable,
  {
    columns: [
      {
        header: '',
        render: item => (
          <>
            {renderEditButton({
              item,
              descriptor: 'rate table tier',
              route: buildPath(routes.portal.rateTableTiers.detail, {
                id: item.id,
                rateTableId: item.rateTableId,
              }),
            })}
          </>
        ),
        cellProps: {
          collapsing: true,
        },
      },
      {
        header: 'Starting Amount',
        column: 'startingAmount',
        sortable: 'startingAmountForSorting',
      },
      {
        header: 'Ending Amount',
        column: 'endingAmount',
      },
      {
        header: 'Cost Per Unit',
        column: 'costPerUnit',
      },
      {
        header: 'Fixed Cost',
        render: item => (
          <>
            {item.fixedCost && item.fixedCost !== '$0.00' && (
              <div>
                <span style={{fontWeight: 'bold'}}>{item.fixedCost}</span> fixed
                price
              </div>
            )}
          </>
        ),
      },
    ],
    searchFieldNames: ['startingAmount', 'endingAmount'],
    defaultSort: {
      column: 'startingAmountForSorting',
      direction: 'ASC',
    },
  }
);
