/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export class IList<T> extends Array<T> {}
export class List<T> extends Array<T> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount: number;
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount!: number;
}

// customer definition
export interface Response<T> {
  data: T | null;
  hasErrors: boolean;
  errors: Error[];
}

export interface Error {
  propertyName: string;
  errorMessage: string;
}

export interface AdvancedQueryPage<T> {
  items: T[];
  page: number;
  pageSize: number;
  pageCount: number;
  totalItemCount: number;
}

export class AccounteNotesService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<AccountNoteSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/account-notes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateAccountNoteRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountNoteDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/account-notes';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountNoteDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/account-notes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateAccountNoteRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountNoteDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/account-notes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/account-notes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AccountsService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<AccountSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/accounts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateAccountRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/accounts';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByOrganizationId(
    params: {
      /**  */
      organizationId?: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<AccountSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/accounts/by-organization';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        OrganizationId: params['organizationId'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/accounts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateAccountRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/accounts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/accounts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByCurrentUser(options: IRequestOptions = {}): Promise<Response<AccountForCustomer>> {
    return new Promise((resolve, reject) => {
      let url = '/by-current-user';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByAccountNumberForCustomerPortal(
    params: {
      /**  */
      organizationSlug?: string;
      /**  */
      accountNumber?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountForCustomer>> {
    return new Promise((resolve, reject) => {
      let url = '/by-account-number';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { OrganizationSlug: params['organizationSlug'], AccountNumber: params['accountNumber'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static upload(
    params: {
      /**  */
      organizationId?: number;
      /**  */
      rateTableId?: number;
      /**  */
      file?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<AccountDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/accounts/accounts/upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = { organizationId: params['organizationId'], rateTableId: params['rateTableId'] };
      let data = null as any;
      data = new FormData();
      if (params['file']) {
        data.append('file', params['file'] as any);
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CompileTimeStaticsService {
  /**
   *
   */
  static getAll(options: IRequestOptions = {}): Promise<Response<string>> {
    return new Promise((resolve, reject) => {
      let url = '/api/compile-time-statics';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FilesService {
  /**
   *
   */
  static upload(
    params: {
      /**  */
      file?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UploadFileResponse>> {
    return new Promise((resolve, reject) => {
      let url = '/upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null as any;
      data = new FormData();
      if (params['file']) {
        data.append('file', params['file'] as any);
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class IntegrationsService {
  /**
   *
   */
  static postPaymentToPayStar(
    params: {
      /**  */
      body?: PostPaymentToPayStarRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<ClientPaymentSessionResponse>> {
    return new Promise((resolve, reject) => {
      let url = '/api/integrations/paystar-payment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MeterReadingsService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<MeterReadingSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateMeterReadingRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<MeterReadingSummaryDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByOrganizationId(
    params: {
      /**  */
      organizationId?: number;
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<MeterReadingSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings/by-organization';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        OrganizationId: params['organizationId'],
        StartDate: params['startDate'],
        EndDate: params['endDate'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByAccountId(
    params: {
      /**  */
      accountId?: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<MeterReadingSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings/by-account';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        AccountId: params['accountId'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<MeterReadingDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateMeterReadingRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<MeterReadingSummaryDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCostBetweenRecentMeterReadings(
    params: {
      /**  */
      accountId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RateTableCalculationResponse>> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings/cost-between-recent-meter-readings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { AccountId: params['accountId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getExcelReport(
    params: {
      /**  */
      organizationId?: number;
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<MeterReadingsReportResponse>> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings/excel-report';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        OrganizationId: params['organizationId'],
        StartDate: params['startDate'],
        EndDate: params['endDate']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteByIdForCustomerPortal(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/meter-readings/{id}/customer-portal';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class OrganizationsService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<OrganizationSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/organizations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateOrganizationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<OrganizationDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/organizations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<OrganizationDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/organizations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateOrganizationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<OrganizationDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/organizations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/organizations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getContextById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<OrganizationContextDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/organizations/{id}/context';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PaymentsService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      accountId?: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<PaymentSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/payments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        AccountId: params['accountId'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreatePaymentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/payments';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/payments/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdatePaymentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/payments/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/payments/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RateTablesService {
  /**
   *
   */
  static getAllByOrganization(
    params: {
      /**  */
      organizationId?: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<RateTableSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-tables/by-organization';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        OrganizationId: params['organizationId'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRateTableCalculation(
    params: {
      /**  */
      startMeterReadingAmount?: number;
      /**  */
      endMeterReadingAmount?: number;
      /**  */
      rateTableId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<ManualRateTableCalculationResponse>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-tables/manual-rate-table-calculation';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        startMeterReadingAmount: params['startMeterReadingAmount'],
        endMeterReadingAmount: params['endMeterReadingAmount'],
        rateTableId: params['rateTableId']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RateTableDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-tables/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateRateTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RateTableDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-tables/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-tables/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByRateCode(
    params: {
      /**  */
      rateCode: string;
      /**  */
      organizationId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RateTableDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-tables/rate-code/{rateCode}';
      url = url.replace('{rateCode}', params['rateCode'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { organizationId: params['organizationId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateRateTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RateTableDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-tables';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RateTableTiersService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<RateTableTierSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-table-tiers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateRateTableTierRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RateTableTierDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-table-tiers';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByRateTable(
    params: {
      /**  */
      rateTableId?: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<RateTableTierSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-table-tiers/by-rate-table-id';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        RateTableId: params['rateTableId'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RateTableTierDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-table-tiers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateRateTableTierRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RateTableTierDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-table-tiers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/rate-table-tiers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SettingsService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<SettingSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/settings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<SettingSummaryDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static save(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateSettingRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<SettingSummaryDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StatusService {
  /**
   *
   */
  static get(options: IRequestOptions = {}): Promise<Response<string>> {
    return new Promise((resolve, reject) => {
      let url = '/api/status';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UsersService {
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserGetDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/users';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<UserSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/users';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByOrganization(
    params: {
      /**  */
      organizationId?: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<UserSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = '/api/users/by-organization';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        OrganizationId: params['organizationId'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMe(
    params: {
      /**  */
      request?: any | null;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserGetDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/users/me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { request: params['request'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePassword(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateUserPasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserGetDto>> {
    return new Promise((resolve, reject) => {
      let url = '/api/users/{id}/password-update';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendPasswordReset(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/users/{id}/send-reset';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class WebhooksService {
  /**
   *
   */
  static triggerWebhook(options: IRequestOptions = {}): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = '/api/webhooks';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AdvancedQueryColumnFilter {
  /**  */
  'fieldName': string;

  /**  */
  'fieldValue': string;

  /**  */
  'exactMatch': boolean;

  constructor(data: undefined | any = {}) {
    this['fieldName'] = data['fieldName'];
    this['fieldValue'] = data['fieldValue'];
    this['exactMatch'] = data['exactMatch'];
  }
}

export class AdvancedQueryColumnSort {
  /**  */
  'fieldName': string;

  /**  */
  'descending': boolean;

  constructor(data: undefined | any = {}) {
    this['fieldName'] = data['fieldName'];
    this['descending'] = data['descending'];
  }
}

export class AccountNoteSummaryDto {
  /**  */
  'id': number;

  /**  */
  'accountId': number;

  /**  */
  'note': string;

  /**  */
  'userName': string;

  /**  */
  'noteDate': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['accountId'] = data['accountId'];
    this['note'] = data['note'];
    this['userName'] = data['userName'];
    this['noteDate'] = data['noteDate'];
  }
}

export class Error {
  /**  */
  'propertyName': string;

  /**  */
  'errorMessage': string;

  constructor(data: undefined | any = {}) {
    this['propertyName'] = data['propertyName'];
    this['errorMessage'] = data['errorMessage'];
  }
}

export class CreateAccountNoteRequest {
  /**  */
  'organizationId': number;

  /**  */
  'accountId': number;

  /**  */
  'note': string;

  /**  */
  'noteDate': Date;

  constructor(data: undefined | any = {}) {
    this['organizationId'] = data['organizationId'];
    this['accountId'] = data['accountId'];
    this['note'] = data['note'];
    this['noteDate'] = data['noteDate'];
  }
}

export class AccountDto {
  /**  */
  'rateTableId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'meterNumber': string;

  /**  */
  'name': string;

  /**  */
  'email': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'rateCode': string;

  constructor(data: undefined | any = {}) {
    this['rateTableId'] = data['rateTableId'];
    this['accountNumber'] = data['accountNumber'];
    this['meterNumber'] = data['meterNumber'];
    this['name'] = data['name'];
    this['email'] = data['email'];
    this['phoneNumber'] = data['phoneNumber'];
    this['rateCode'] = data['rateCode'];
  }
}

export class AccountNoteDetailDto {
  /**  */
  'account': AccountDto;

  /**  */
  'id': number;

  /**  */
  'accountId': number;

  /**  */
  'note': string;

  /**  */
  'noteDate': Date;

  constructor(data: undefined | any = {}) {
    this['account'] = data['account'];
    this['id'] = data['id'];
    this['accountId'] = data['accountId'];
    this['note'] = data['note'];
    this['noteDate'] = data['noteDate'];
  }
}

export class UpdateAccountNoteRequest {
  /**  */
  'accountId': number;

  /**  */
  'note': string;

  /**  */
  'noteDate': Date;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['note'] = data['note'];
    this['noteDate'] = data['noteDate'];
  }
}

export class EmptyResponse {
  /**  */
  'hasErrors': boolean;

  /**  */
  'errors': Error[];

  constructor(data: undefined | any = {}) {
    this['hasErrors'] = data['hasErrors'];
    this['errors'] = data['errors'];
  }
}

export class AccountSummaryDto {
  /**  */
  'id': number;

  /**  */
  'meterNumber': string;

  /**  */
  'accountNumber': string;

  /**  */
  'name': string;

  /**  */
  'email': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'addressLine1': string;

  /**  */
  'addressLine2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  /**  */
  'organizationSlug': string;

  /**  */
  'hasMeterReadings': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['meterNumber'] = data['meterNumber'];
    this['accountNumber'] = data['accountNumber'];
    this['name'] = data['name'];
    this['email'] = data['email'];
    this['phoneNumber'] = data['phoneNumber'];
    this['addressLine1'] = data['addressLine1'];
    this['addressLine2'] = data['addressLine2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
    this['organizationSlug'] = data['organizationSlug'];
    this['hasMeterReadings'] = data['hasMeterReadings'];
  }
}

export class CreateAccountRequest {
  /**  */
  'organizationId': number;

  /**  */
  'readingAmount': number;

  /**  */
  'rateTableId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'meterNumber': string;

  /**  */
  'name': string;

  /**  */
  'email': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'rateCode': string;

  constructor(data: undefined | any = {}) {
    this['organizationId'] = data['organizationId'];
    this['readingAmount'] = data['readingAmount'];
    this['rateTableId'] = data['rateTableId'];
    this['accountNumber'] = data['accountNumber'];
    this['meterNumber'] = data['meterNumber'];
    this['name'] = data['name'];
    this['email'] = data['email'];
    this['phoneNumber'] = data['phoneNumber'];
    this['rateCode'] = data['rateCode'];
  }
}

export class AccountDetailDto {
  /**  */
  'rateTableName': string;

  /**  */
  'accountNotes': AccountNoteSummaryDto[];

  /**  */
  'unitType': string;

  /**  */
  'id': number;

  /**  */
  'rateTableId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'meterNumber': string;

  /**  */
  'name': string;

  /**  */
  'email': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'rateCode': string;

  constructor(data: undefined | any = {}) {
    this['rateTableName'] = data['rateTableName'];
    this['accountNotes'] = data['accountNotes'];
    this['unitType'] = data['unitType'];
    this['id'] = data['id'];
    this['rateTableId'] = data['rateTableId'];
    this['accountNumber'] = data['accountNumber'];
    this['meterNumber'] = data['meterNumber'];
    this['name'] = data['name'];
    this['email'] = data['email'];
    this['phoneNumber'] = data['phoneNumber'];
    this['rateCode'] = data['rateCode'];
  }
}

export class UpdateAccountRequest {
  /**  */
  'rateTableId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'meterNumber': string;

  /**  */
  'name': string;

  /**  */
  'email': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'rateCode': string;

  constructor(data: undefined | any = {}) {
    this['rateTableId'] = data['rateTableId'];
    this['accountNumber'] = data['accountNumber'];
    this['meterNumber'] = data['meterNumber'];
    this['name'] = data['name'];
    this['email'] = data['email'];
    this['phoneNumber'] = data['phoneNumber'];
    this['rateCode'] = data['rateCode'];
  }
}

export class MeterReadingSummaryDto {
  /**  */
  'id': number;

  /**  */
  'accountId': number;

  /**  */
  'meterNumber': string;

  /**  */
  'unitsUsed': string;

  /**  */
  'unitType': string;

  /**  */
  'accountNumber': string;

  /**  */
  'accountName': string;

  /**  */
  'createdDate': string;

  /**  */
  'readingDate': string;

  /**  */
  'paymentReferenceNumber': string;

  /**  */
  'paymentReferenceNumberLink': string;

  /**  */
  'readingDateForGrouping': string;

  /**  */
  'readingAmount': string;

  /**  */
  'comments': string;

  /**  */
  'amountDue': string;

  /**  */
  'amountReceived': string;

  /**  */
  'isSubmittedThroughPortal': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['accountId'] = data['accountId'];
    this['meterNumber'] = data['meterNumber'];
    this['unitsUsed'] = data['unitsUsed'];
    this['unitType'] = data['unitType'];
    this['accountNumber'] = data['accountNumber'];
    this['accountName'] = data['accountName'];
    this['createdDate'] = data['createdDate'];
    this['readingDate'] = data['readingDate'];
    this['paymentReferenceNumber'] = data['paymentReferenceNumber'];
    this['paymentReferenceNumberLink'] = data['paymentReferenceNumberLink'];
    this['readingDateForGrouping'] = data['readingDateForGrouping'];
    this['readingAmount'] = data['readingAmount'];
    this['comments'] = data['comments'];
    this['amountDue'] = data['amountDue'];
    this['amountReceived'] = data['amountReceived'];
    this['isSubmittedThroughPortal'] = data['isSubmittedThroughPortal'];
  }
}

export class AccountForCustomer {
  /**  */
  'id': string;

  /**  */
  'accountNumber': string;

  /**  */
  'name': string;

  /**  */
  'email': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'rateCode': string;

  /**  */
  'organizationName': string;

  /**  */
  'alwaysAllowPayments': boolean;

  /**  */
  'allowFreeFormPayments': boolean;

  /**  */
  'allowMultiplePayments': boolean;

  /**  */
  'rateTableId': string;

  /**  */
  'lastMeterReading': MeterReadingSummaryDto;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['accountNumber'] = data['accountNumber'];
    this['name'] = data['name'];
    this['email'] = data['email'];
    this['phoneNumber'] = data['phoneNumber'];
    this['rateCode'] = data['rateCode'];
    this['organizationName'] = data['organizationName'];
    this['alwaysAllowPayments'] = data['alwaysAllowPayments'];
    this['allowFreeFormPayments'] = data['allowFreeFormPayments'];
    this['allowMultiplePayments'] = data['allowMultiplePayments'];
    this['rateTableId'] = data['rateTableId'];
    this['lastMeterReading'] = data['lastMeterReading'];
  }
}

export class UploadFileResponse {
  /**  */
  'uri': string;

  constructor(data: undefined | any = {}) {
    this['uri'] = data['uri'];
  }
}

export class PostPaymentToPayStarRequest {
  /**  */
  'accountId': number;

  /**  */
  'freeFormAmount': number;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['freeFormAmount'] = data['freeFormAmount'];
  }
}

export class ClientPaymentSessionResponse {
  /**  */
  'paymentSessionIdentifier': string;

  /**  */
  'paymentLogInLink': string;

  constructor(data: undefined | any = {}) {
    this['paymentSessionIdentifier'] = data['paymentSessionIdentifier'];
    this['paymentLogInLink'] = data['paymentLogInLink'];
  }
}

export class Currency {
  /**  */
  'value': number;

  /**  */
  'code': string;

  constructor(data: undefined | any = {}) {
    this['value'] = data['value'];
    this['code'] = data['code'];
  }
}

export class CreateMeterReadingRequest {
  /**  */
  'calculateAmountDue': boolean;

  /**  */
  'accountId': number;

  /**  */
  'readingDate': Date;

  /**  */
  'readingAmount': number;

  /**  */
  'unitsUsed': number;

  /**  */
  'isSubmittedThroughPortal': boolean;

  /**  */
  'paymentReferenceNumber': string;

  /**  */
  'comments': string;

  /**  */
  'amountDue': Currency;

  /**  */
  'amountReceived': Currency;

  constructor(data: undefined | any = {}) {
    this['calculateAmountDue'] = data['calculateAmountDue'];
    this['accountId'] = data['accountId'];
    this['readingDate'] = data['readingDate'];
    this['readingAmount'] = data['readingAmount'];
    this['unitsUsed'] = data['unitsUsed'];
    this['isSubmittedThroughPortal'] = data['isSubmittedThroughPortal'];
    this['paymentReferenceNumber'] = data['paymentReferenceNumber'];
    this['comments'] = data['comments'];
    this['amountDue'] = data['amountDue'];
    this['amountReceived'] = data['amountReceived'];
  }
}

export class MeterReadingDetailDto {
  /**  */
  'accountNumber': string;

  /**  */
  'unitType': string;

  /**  */
  'id': number;

  /**  */
  'accountId': number;

  /**  */
  'readingDate': Date;

  /**  */
  'readingAmount': number;

  /**  */
  'unitsUsed': number;

  /**  */
  'isSubmittedThroughPortal': boolean;

  /**  */
  'paymentReferenceNumber': string;

  /**  */
  'comments': string;

  /**  */
  'amountDue': Currency;

  /**  */
  'amountReceived': Currency;

  constructor(data: undefined | any = {}) {
    this['accountNumber'] = data['accountNumber'];
    this['unitType'] = data['unitType'];
    this['id'] = data['id'];
    this['accountId'] = data['accountId'];
    this['readingDate'] = data['readingDate'];
    this['readingAmount'] = data['readingAmount'];
    this['unitsUsed'] = data['unitsUsed'];
    this['isSubmittedThroughPortal'] = data['isSubmittedThroughPortal'];
    this['paymentReferenceNumber'] = data['paymentReferenceNumber'];
    this['comments'] = data['comments'];
    this['amountDue'] = data['amountDue'];
    this['amountReceived'] = data['amountReceived'];
  }
}

export class UpdateMeterReadingRequest {
  /**  */
  'calculateAmountDue': boolean;

  /**  */
  'accountId': number;

  /**  */
  'readingDate': Date;

  /**  */
  'readingAmount': number;

  /**  */
  'unitsUsed': number;

  /**  */
  'isSubmittedThroughPortal': boolean;

  /**  */
  'paymentReferenceNumber': string;

  /**  */
  'comments': string;

  /**  */
  'amountDue': Currency;

  /**  */
  'amountReceived': Currency;

  constructor(data: undefined | any = {}) {
    this['calculateAmountDue'] = data['calculateAmountDue'];
    this['accountId'] = data['accountId'];
    this['readingDate'] = data['readingDate'];
    this['readingAmount'] = data['readingAmount'];
    this['unitsUsed'] = data['unitsUsed'];
    this['isSubmittedThroughPortal'] = data['isSubmittedThroughPortal'];
    this['paymentReferenceNumber'] = data['paymentReferenceNumber'];
    this['comments'] = data['comments'];
    this['amountDue'] = data['amountDue'];
    this['amountReceived'] = data['amountReceived'];
  }
}

export class RateTableCalculationResponse {
  /**  */
  'cost': Currency;

  /**  */
  'unitsUsed': number;

  /**  */
  'unitMeasurement': string;

  constructor(data: undefined | any = {}) {
    this['cost'] = data['cost'];
    this['unitsUsed'] = data['unitsUsed'];
    this['unitMeasurement'] = data['unitMeasurement'];
  }
}

export class MeterReadingsReportResponse {
  /**  */
  'fileName': string;

  /**  */
  'data': string;

  constructor(data: undefined | any = {}) {
    this['fileName'] = data['fileName'];
    this['data'] = data['data'];
  }
}

export class OrganizationSummaryDto {
  /**  */
  'id': number;

  /**  */
  'name': string;

  /**  */
  'addressLine1': string;

  /**  */
  'addressLine2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['addressLine1'] = data['addressLine1'];
    this['addressLine2'] = data['addressLine2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
  }
}

export class CreateOrganizationRequest {
  /**  */
  'name': string;

  /**  */
  'slug': string;

  /**  */
  'addressLine1': string;

  /**  */
  'addressLine2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  /**  */
  'billingDay': number;

  /**  */
  'meterReadingInstructionsLink': string;

  /**  */
  'rateTableInstructionsLink': string;

  /**  */
  'billingSystemDigitCountAdjustment': number;

  /**  */
  'alwaysAllowPayments': boolean;

  /**  */
  'allowFreeFormPayments': boolean;

  /**  */
  'allowMultiplePayments': boolean;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['slug'] = data['slug'];
    this['addressLine1'] = data['addressLine1'];
    this['addressLine2'] = data['addressLine2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
    this['billingDay'] = data['billingDay'];
    this['meterReadingInstructionsLink'] = data['meterReadingInstructionsLink'];
    this['rateTableInstructionsLink'] = data['rateTableInstructionsLink'];
    this['billingSystemDigitCountAdjustment'] = data['billingSystemDigitCountAdjustment'];
    this['alwaysAllowPayments'] = data['alwaysAllowPayments'];
    this['allowFreeFormPayments'] = data['allowFreeFormPayments'];
    this['allowMultiplePayments'] = data['allowMultiplePayments'];
  }
}

export class OrganizationDetailDto {
  /**  */
  'id': number;

  /**  */
  'name': string;

  /**  */
  'slug': string;

  /**  */
  'addressLine1': string;

  /**  */
  'addressLine2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  /**  */
  'billingDay': number;

  /**  */
  'meterReadingInstructionsLink': string;

  /**  */
  'rateTableInstructionsLink': string;

  /**  */
  'billingSystemDigitCountAdjustment': number;

  /**  */
  'alwaysAllowPayments': boolean;

  /**  */
  'allowFreeFormPayments': boolean;

  /**  */
  'allowMultiplePayments': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['slug'] = data['slug'];
    this['addressLine1'] = data['addressLine1'];
    this['addressLine2'] = data['addressLine2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
    this['billingDay'] = data['billingDay'];
    this['meterReadingInstructionsLink'] = data['meterReadingInstructionsLink'];
    this['rateTableInstructionsLink'] = data['rateTableInstructionsLink'];
    this['billingSystemDigitCountAdjustment'] = data['billingSystemDigitCountAdjustment'];
    this['alwaysAllowPayments'] = data['alwaysAllowPayments'];
    this['allowFreeFormPayments'] = data['allowFreeFormPayments'];
    this['allowMultiplePayments'] = data['allowMultiplePayments'];
  }
}

export class UpdateOrganizationRequest {
  /**  */
  'name': string;

  /**  */
  'slug': string;

  /**  */
  'addressLine1': string;

  /**  */
  'addressLine2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  /**  */
  'billingDay': number;

  /**  */
  'meterReadingInstructionsLink': string;

  /**  */
  'rateTableInstructionsLink': string;

  /**  */
  'billingSystemDigitCountAdjustment': number;

  /**  */
  'alwaysAllowPayments': boolean;

  /**  */
  'allowFreeFormPayments': boolean;

  /**  */
  'allowMultiplePayments': boolean;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['slug'] = data['slug'];
    this['addressLine1'] = data['addressLine1'];
    this['addressLine2'] = data['addressLine2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
    this['billingDay'] = data['billingDay'];
    this['meterReadingInstructionsLink'] = data['meterReadingInstructionsLink'];
    this['rateTableInstructionsLink'] = data['rateTableInstructionsLink'];
    this['billingSystemDigitCountAdjustment'] = data['billingSystemDigitCountAdjustment'];
    this['alwaysAllowPayments'] = data['alwaysAllowPayments'];
    this['allowFreeFormPayments'] = data['allowFreeFormPayments'];
    this['allowMultiplePayments'] = data['allowMultiplePayments'];
  }
}

export class OrganizationContextDto {
  /**  */
  'id': number;

  /**  */
  'name': string;

  /**  */
  'addressLine1': string;

  /**  */
  'addressLine2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['addressLine1'] = data['addressLine1'];
    this['addressLine2'] = data['addressLine2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
  }
}

export class PaymentSummaryDto {
  constructor(data: undefined | any = {}) {}
}

export class CreatePaymentRequest {
  /**  */
  'accountId': number;

  /**  */
  'paymentAmount': Currency;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['paymentAmount'] = data['paymentAmount'];
  }
}

export class PaymentDetailDto {
  /**  */
  'id': number;

  /**  */
  'accountId': number;

  /**  */
  'paymentAmount': Currency;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['accountId'] = data['accountId'];
    this['paymentAmount'] = data['paymentAmount'];
  }
}

export class UpdatePaymentRequest {
  /**  */
  'paymentAmount': Currency;

  constructor(data: undefined | any = {}) {
    this['paymentAmount'] = data['paymentAmount'];
  }
}

export class RateTableSummaryDto {
  /**  */
  'id': number;

  /**  */
  'units': string;

  /**  */
  'name': string;

  /**  */
  'unitMeasurement': string;

  /**  */
  'minimumAmount': string;

  /**  */
  'rateCode': string;

  /**  */
  'doNotImport': boolean;

  /**  */
  'manuallyCalculated': boolean;

  /**  */
  'roundingEnabled': boolean;

  /**  */
  'roundUpAt': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['units'] = data['units'];
    this['name'] = data['name'];
    this['unitMeasurement'] = data['unitMeasurement'];
    this['minimumAmount'] = data['minimumAmount'];
    this['rateCode'] = data['rateCode'];
    this['doNotImport'] = data['doNotImport'];
    this['manuallyCalculated'] = data['manuallyCalculated'];
    this['roundingEnabled'] = data['roundingEnabled'];
    this['roundUpAt'] = data['roundUpAt'];
  }
}

export class ManualRateTableCalculationResponse {
  /**  */
  'cost': Currency;

  constructor(data: undefined | any = {}) {
    this['cost'] = data['cost'];
  }
}

export class RateTableDetailDto {
  /**  */
  'id': number;

  /**  */
  'organizationId': number;

  /**  */
  'name': string;

  /**  */
  'unitMeasurement': string;

  /**  */
  'minimumAmount': Currency;

  /**  */
  'units': number;

  /**  */
  'rateCode': string;

  /**  */
  'roundingEnabled': boolean;

  /**  */
  'roundUpAt': number;

  /**  */
  'doNotImport': boolean;

  /**  */
  'manuallyCalculated': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['organizationId'] = data['organizationId'];
    this['name'] = data['name'];
    this['unitMeasurement'] = data['unitMeasurement'];
    this['minimumAmount'] = data['minimumAmount'];
    this['units'] = data['units'];
    this['rateCode'] = data['rateCode'];
    this['roundingEnabled'] = data['roundingEnabled'];
    this['roundUpAt'] = data['roundUpAt'];
    this['doNotImport'] = data['doNotImport'];
    this['manuallyCalculated'] = data['manuallyCalculated'];
  }
}

export class UpdateRateTableRequest {
  /**  */
  'name': string;

  /**  */
  'unitMeasurement': string;

  /**  */
  'minimumAmount': Currency;

  /**  */
  'units': number;

  /**  */
  'rateCode': string;

  /**  */
  'roundingEnabled': boolean;

  /**  */
  'roundUpAt': number;

  /**  */
  'doNotImport': boolean;

  /**  */
  'manuallyCalculated': boolean;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['unitMeasurement'] = data['unitMeasurement'];
    this['minimumAmount'] = data['minimumAmount'];
    this['units'] = data['units'];
    this['rateCode'] = data['rateCode'];
    this['roundingEnabled'] = data['roundingEnabled'];
    this['roundUpAt'] = data['roundUpAt'];
    this['doNotImport'] = data['doNotImport'];
    this['manuallyCalculated'] = data['manuallyCalculated'];
  }
}

export class CreateRateTableRequest {
  /**  */
  'organizationId': number;

  /**  */
  'name': string;

  /**  */
  'unitMeasurement': string;

  /**  */
  'minimumAmount': Currency;

  /**  */
  'units': number;

  /**  */
  'rateCode': string;

  /**  */
  'roundingEnabled': boolean;

  /**  */
  'roundUpAt': number;

  /**  */
  'doNotImport': boolean;

  /**  */
  'manuallyCalculated': boolean;

  constructor(data: undefined | any = {}) {
    this['organizationId'] = data['organizationId'];
    this['name'] = data['name'];
    this['unitMeasurement'] = data['unitMeasurement'];
    this['minimumAmount'] = data['minimumAmount'];
    this['units'] = data['units'];
    this['rateCode'] = data['rateCode'];
    this['roundingEnabled'] = data['roundingEnabled'];
    this['roundUpAt'] = data['roundUpAt'];
    this['doNotImport'] = data['doNotImport'];
    this['manuallyCalculated'] = data['manuallyCalculated'];
  }
}

export class RateTableTierSummaryDto {
  /**  */
  'id': number;

  /**  */
  'rateTableId': number;

  /**  */
  'startingAmountForSorting': string;

  /**  */
  'startingAmount': string;

  /**  */
  'endingAmount': string;

  /**  */
  'costPerUnit': string;

  /**  */
  'fixedCost': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['rateTableId'] = data['rateTableId'];
    this['startingAmountForSorting'] = data['startingAmountForSorting'];
    this['startingAmount'] = data['startingAmount'];
    this['endingAmount'] = data['endingAmount'];
    this['costPerUnit'] = data['costPerUnit'];
    this['fixedCost'] = data['fixedCost'];
  }
}

export class CreateRateTableTierRequest {
  /**  */
  'rateTableId': number;

  /**  */
  'startingAmount': number;

  /**  */
  'endingAmount': number;

  /**  */
  'hasNoEndingAmount': boolean;

  /**  */
  'costPerUnit': Currency;

  /**  */
  'fixedCost': Currency;

  constructor(data: undefined | any = {}) {
    this['rateTableId'] = data['rateTableId'];
    this['startingAmount'] = data['startingAmount'];
    this['endingAmount'] = data['endingAmount'];
    this['hasNoEndingAmount'] = data['hasNoEndingAmount'];
    this['costPerUnit'] = data['costPerUnit'];
    this['fixedCost'] = data['fixedCost'];
  }
}

export class RateTableTierDetailDto {
  /**  */
  'id': number;

  /**  */
  'startingAmountForSorting': string;

  /**  */
  'rateTableId': number;

  /**  */
  'startingAmount': number;

  /**  */
  'endingAmount': number;

  /**  */
  'hasNoEndingAmount': boolean;

  /**  */
  'costPerUnit': Currency;

  /**  */
  'fixedCost': Currency;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['startingAmountForSorting'] = data['startingAmountForSorting'];
    this['rateTableId'] = data['rateTableId'];
    this['startingAmount'] = data['startingAmount'];
    this['endingAmount'] = data['endingAmount'];
    this['hasNoEndingAmount'] = data['hasNoEndingAmount'];
    this['costPerUnit'] = data['costPerUnit'];
    this['fixedCost'] = data['fixedCost'];
  }
}

export class UpdateRateTableTierRequest {
  /**  */
  'startingAmount': number;

  /**  */
  'endingAmount': number;

  /**  */
  'hasNoEndingAmount': boolean;

  /**  */
  'costPerUnit': Currency;

  /**  */
  'fixedCost': Currency;

  constructor(data: undefined | any = {}) {
    this['startingAmount'] = data['startingAmount'];
    this['endingAmount'] = data['endingAmount'];
    this['hasNoEndingAmount'] = data['hasNoEndingAmount'];
    this['costPerUnit'] = data['costPerUnit'];
    this['fixedCost'] = data['fixedCost'];
  }
}

export class SettingSummaryDto {
  /**  */
  'id': number;

  /**  */
  'key': string;

  /**  */
  'type': string;

  /**  */
  'enum': string;

  /**  */
  'value': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['key'] = data['key'];
    this['type'] = data['type'];
    this['enum'] = data['enum'];
    this['value'] = data['value'];
  }
}

export class UpdateSettingRequest {
  /**  */
  'value': string;

  constructor(data: undefined | any = {}) {
    this['value'] = data['value'];
  }
}

export class CreateUserRequest {
  /**  */
  'claims': string[];

  /**  */
  'organizationId': number;

  /**  */
  'accountId': number;

  /**  */
  'emailAddress': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'role': string;

  constructor(data: undefined | any = {}) {
    this['claims'] = data['claims'];
    this['organizationId'] = data['organizationId'];
    this['accountId'] = data['accountId'];
    this['emailAddress'] = data['emailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['role'] = data['role'];
  }
}

export class UserGetDto {
  /**  */
  'id': number;

  /**  */
  'organizationId': number;

  /**  */
  'accountId': number;

  /**  */
  'emailAddress': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'role': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['organizationId'] = data['organizationId'];
    this['accountId'] = data['accountId'];
    this['emailAddress'] = data['emailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['role'] = data['role'];
  }
}

export class UserSummaryDto {
  /**  */
  'id': number;

  /**  */
  'emailAddress': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'role': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['emailAddress'] = data['emailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['role'] = data['role'];
  }
}

export class UserDetailDto {
  /**  */
  'claims': string[];

  /**  */
  'id': number;

  /**  */
  'organizationId': number;

  /**  */
  'accountId': number;

  /**  */
  'emailAddress': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'role': string;

  constructor(data: undefined | any = {}) {
    this['claims'] = data['claims'];
    this['id'] = data['id'];
    this['organizationId'] = data['organizationId'];
    this['accountId'] = data['accountId'];
    this['emailAddress'] = data['emailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['role'] = data['role'];
  }
}

export class UpdateUserRequest {
  /**  */
  'claims': string[];

  /**  */
  'organizationId': number;

  /**  */
  'accountId': number;

  /**  */
  'emailAddress': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'role': string;

  constructor(data: undefined | any = {}) {
    this['claims'] = data['claims'];
    this['organizationId'] = data['organizationId'];
    this['accountId'] = data['accountId'];
    this['emailAddress'] = data['emailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['role'] = data['role'];
  }
}

export class GetCurrentUserRequest {
  constructor(data: undefined | any = {}) {}
}

export class UpdateUserPasswordRequest {
  /**  */
  'confirmNewPassword': string;

  /**  */
  'currentPassword': string;

  /**  */
  'newPassword': string;

  constructor(data: undefined | any = {}) {
    this['confirmNewPassword'] = data['confirmNewPassword'];
    this['currentPassword'] = data['currentPassword'];
    this['newPassword'] = data['newPassword'];
  }
}
