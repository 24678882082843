import React from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {
  RateTableTiersService,
  RateTablesService,
  RateTableDetailDto,
  RateTableTierDetailDto,
} from '../api/generated';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {DeleteButton} from '../components/confirm-delete-button';
import {Form} from '../forms';
import {useNotification} from '../hooks/use-notifications';
import {routes, buildPath} from '../routes';
import {useOrganizationContext} from '../selectors';

export const RateTableTierCreate = () => {
  const history = useHistory();
  const notifications = useNotification();
  const context = useOrganizationContext();
  const match = useRouteMatch<{rateTableId: string}>();
  const rateTableId = Number(match.params.rateTableId);

  const fetchRateTable = useAsync(async () => {
    const {data} = await RateTablesService.getById({
      id: rateTableId,
    });
    return data;
  }, [rateTableId]);

  const rateTable = fetchRateTable.value || undefined;

  const RateTableDashboardUrl = buildPath(routes.portal.rateTables.dashboard, {
    id: rateTableId,
  });

  const breadcrumbs = [
    {title: 'Rate Tiers', url: RateTableDashboardUrl},
    {title: 'Create'},
  ];

  const onSubmit = async (values) => {
    values.organizationId = context.organizationId;
    values.rateTableId = rateTableId;

    const response = await RateTableTiersService.create({body: values});
    if (response.hasErrors) {
      return response;
    }

    notifications.success('Rate Tier Created');
    history.push(RateTableDashboardUrl);
  };

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchRateTable}>
        {rateTable && (
          <Form.Container>
            <Form
              onSubmit={onSubmit}
              render={({values}) => (
                <>
                  <Form.Section title="Rate Tier Information">
                    <FormFields rateTable={rateTable} values={values} />
                  </Form.Section>
                  <div className="form-actions">
                    <Form.Button type="submit" primary>
                      Create Tier
                    </Form.Button>
                    <Form.Button secondary as={Link} to={RateTableDashboardUrl}>
                      Cancel
                    </Form.Button>
                  </div>
                </>
              )}
            />
          </Form.Container>
        )}
      </AsyncStateContainer>
    </BasicPage>
  );
};

export const RateTableTierDetails = () => {
  const history = useHistory();
  const match = useRouteMatch<{rateTableId: string; id: string}>();
  const id = Number(match.params.id);
  const rateTableId = Number(match.params.rateTableId);
  const notifications = useNotification();

  const fetchRateTableTier = useAsync(async () => {
    const {data} = await RateTableTiersService.getById({
      id: id,
    });
    return data;
  }, [id]);

  const RateTableDashboardUrl = buildPath(routes.portal.rateTables.dashboard, {
    id: fetchRateTableTier.value ? fetchRateTableTier.value.rateTableId : 0,
  });

  const rateTableTier = fetchRateTableTier.value || undefined;

  const fetchRateTable = useAsync(async () => {
    const {data} = await RateTablesService.getById({
      id: rateTableId,
    });
    return data;
  }, [rateTableId]);

  const rateTable = fetchRateTable.value || undefined;

  const breadcrumbs = [
    {title: 'Rate Tiers', url: RateTableDashboardUrl},
    {title: rateTableTier ? rateTableTier.startingAmount.toString() : ''},
  ];

  const onSubmit = async (values) => {
    const response = await RateTableTiersService.update({
      id: id,
      body: values,
    });
    if (response.hasErrors) {
      return response;
    }
    notifications.success('Rate Tier Updated');
    history.push(RateTableDashboardUrl);
  };

  const [deleteRateTableTierState, deleteRateTableTier] = useAsyncFn(
    async () => {
      const response = await RateTableTiersService.deleteById({
        id: id,
      });
      if (response.hasErrors) {
        var errorMessage = response.errors.reduce((acc, item) => {
          return `${acc} \n ${item.errorMessage}`;
        }, '');

        notifications.error(errorMessage);
      } else {
        notifications.success('Rate Tier successfully deleted');
        history.push(routes.portal.rateTables.listing);
      }
    }
  );

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchRateTable}>
        <AsyncStateContainer {...fetchRateTableTier}>
          {rateTable && rateTableTier && (
            <Form.Container>
              <Form
                initialValues={rateTableTier}
                onSubmit={onSubmit}
                render={({values}) => (
                  <>
                    <Form.Section title="Rate Tier Information">
                      <FormFields rateTable={rateTable} values={values} />
                    </Form.Section>
                    <div className="form-actions">
                      <Form.Button type="submit" primary>
                        Update Tier
                      </Form.Button>
                      <Form.Button
                        secondary
                        as={Link}
                        to={RateTableDashboardUrl}
                      >
                        Cancel
                      </Form.Button>

                      <DeleteButton
                        onConfirm={deleteRateTableTier}
                        loading={deleteRateTableTierState.loading}
                        tertiary
                        icon={false}
                      />
                    </div>
                  </>
                )}
              />
            </Form.Container>
          )}
        </AsyncStateContainer>
      </AsyncStateContainer>
    </BasicPage>
  );
};

type FormFields = {
  rateTable: RateTableDetailDto;
  values: RateTableTierDetailDto;
};

const FormFields = (props: FormFields) => {
  return (
    <>
      <Form.Row proportions={[1, 1, 1]}>
        <Form.Input
          type="number"
          fieldName="startingAmount"
          fieldLabel={`Starting Amount (in ${props.rateTable.unitMeasurement.toLowerCase()})`}
        />
        {!props.values.hasNoEndingAmount && (
          <Form.Input
            type="number"
            fieldName="endingAmount"
            fieldLabel={`Ending Amount (in ${props.rateTable.unitMeasurement.toLowerCase()})`}
          />
        )}
        <Form.Checkbox
          fieldName="hasNoEndingAmount"
          fieldLabel={`No Ending Amount`}
          css={{paddingTop: 10}}
        />
      </Form.Row>

      <Form.Section title="Cost">
        <Form.Row>
          <Form.InputCurrency
            type="number"
            fieldName="costPerUnit.value"
            fieldLabel={`Cost Per ${props.rateTable.units} ${props.rateTable.unitMeasurement}`}
          />
          <div style={{margin: 'auto', paddingTop: '10px'}}>+</div>
          <Form.InputCurrency
            type="number"
            fieldName="fixedCost.value"
            fieldLabel="Fixed Price"
          />
        </Form.Row>
      </Form.Section>
    </>
  );
};
