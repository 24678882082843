import React, {useState, useMemo} from 'react';
import {Modal} from 'semantic-ui-react';
import {Form} from '../forms';
import {} from '../api/generated/enums';
import {css} from '@emotion/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalculator} from '@fortawesome/pro-regular-svg-icons';
import {Tooltip} from '../components/tooltip';
import {RateTablesService} from '../api/generated';
import {notifications} from '../utils/notification-service';
import {useAsync} from 'react-use';
import {useOrganizationContext} from '../selectors';
import { CopyButton } from '../components/copy-button';

type MeterReadingCalculatorModal = {
  endMeterReadingAmount: number;
  rateTableId: number;
  applyOnCalculate: (amountDue, readingAmount, unitsUsed) => any;
}

export const MeterReadingCalculatorModal = (props: MeterReadingCalculatorModal) => {
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calculatedAmount, setCalculatedAmount] = useState<number>(0);

  const onSubmit = async (values) => {
    const response = await RateTablesService.getRateTableCalculation({
      startMeterReadingAmount: values.startMeterReadingAmount,
      endMeterReadingAmount: values.endMeterReadingAmount,
      rateTableId: values.rateTableId,
    });
    if (response.hasErrors) {
      return response;
    }
    notifications.info('Calculation Successful');

    setCalculatedAmount(response.data?.cost.value ?? 0);
  };

  const onApplySubmit = async (startMeterReadingAmount, endMeterReadingAmount, rateTableId) => {
    const response = await RateTablesService.getRateTableCalculation({
      startMeterReadingAmount: startMeterReadingAmount,
      endMeterReadingAmount: endMeterReadingAmount,
      rateTableId: rateTableId,
    });
    if (response.hasErrors) {
      return response;
    }
    notifications.info('Calculation Applied');
    
    setCalculatedAmount(response.data?.cost.value ?? 0);

    props.applyOnCalculate(response.data?.cost.value ?? 0, endMeterReadingAmount, endMeterReadingAmount - startMeterReadingAmount);
    
    setCalculatedAmount(0);
  };

  const fetchRateTables = useAsync(async () => {
    const {data} = await RateTablesService.getAllByOrganization({
      organizationId,
    });

    if (data === null) return null;

    return data.items;
  });

  const rateTables = fetchRateTables.value || undefined;

  const mappedRateTables = useMemo(
    () =>
      (rateTables || []).map(x => ({
        key: `${x.name}`,
        text: `${x.name}`,
        value: x.id,
      })),
    [rateTables]
  );

  return (
    <div>
      <Modal
        className="modal-notes"
        css={styles}
        trigger={
          <Tooltip label="Calculate amount due">
            <Form.Button
              
              primary
              type="button"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Calculate Amount Due {' '}
              <FontAwesomeIcon icon={faCalculator} size="lg" />
            </Form.Button>
          </Tooltip>
        }
        open={isModalOpen}
        onOpen={() => setCalculatedAmount(0)}
        closeOnEscape={isModalOpen}
        closeOnDimmerClick={isModalOpen}
        onClose={() => {setIsModalOpen(false);                       
        setCalculatedAmount(0);
        ;}}
        closeIcon
      >
        <Modal.Content>
          <Form
            onSubmit={onSubmit}
            render={({values}) => (
              <>
                <Form.Section title="Calculate Amount Due">
                  <FormFields 
                    endMeterReadingAmount={props.endMeterReadingAmount} 
                    defaultRateTableValue={props.rateTableId} 
                    rateTableOptions={mappedRateTables} 
                  />
                </Form.Section>
                Amount Due: ${Number((calculatedAmount ?? 0) / 100).toFixed(2)}
                <CopyButton                                     
                  value={Number((calculatedAmount ?? 0) / 100).toFixed(2).toString() ?? ""}
                  size="tiny"/>
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Calculate
                  </Form.Button>
                  <Form.Button
                    secondary
                    type="button"
                    onClick={() => {
                      onApplySubmit(values.startMeterReadingAmount, values.endMeterReadingAmount, values.rateTableId);
                      setIsModalOpen(false); 
                    }}
                  >
                    Apply Calculation
                  </Form.Button>
                  <Form.Button
                    secondary
                    type="button"
                    onClick={() => {
                    setIsModalOpen(false);
                    setCalculatedAmount(0);}
                    }
                  >
                    Close
                  </Form.Button>
                </div>
              </>
            )}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

type FormFields = {
  rateTableOptions: {
    key: string;
    text: string;
    value: number;
  }[];
  defaultRateTableValue: number;
  endMeterReadingAmount: number;
};

const FormFields = (props: FormFields) => {
  return (
    <>
      <Form.Input
        type="number"
        min="0"
        fieldName="startMeterReadingAmount"
        fieldLabel="Start Meter Reading Amount"
      />
      <Form.Input
        type="number"
        min="0"
        defaultValue={props.endMeterReadingAmount}
        fieldName="endMeterReadingAmount"
        fieldLabel="End Meter Reading Amount"
      />
      <Form.Dropdown
        fieldLabel="Rate Table"
        fieldName="rateTableId"
        options={props.rateTableOptions}
        defaultValue={props.defaultRateTableValue}
        search
        selection
      />
    </>
  );
};

const styles = css`
  &.modal-notes {
    position: fixed;
    top: 80px;
    bottom: 1;
  }

  &.menu-item {
    flex-direction: row;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  &.modal-create-header {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  &.modal-create-header-item {
    flex-direction: row;
    display: flex;
    margin-left: auto;
  }
`;
