import {css} from '@emotion/core';

export const customerStyles = css`
  .divider {
    width: 80%;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    border-top: 2px solid lightgray;
  }

  .center-content-box {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
  }

  .meter-reading-main-content {
    margin-top: 150px;
    margin-bottom: 15px;
    padding-bottom: 25px;
    width: 600px;
    position: relative;
    padding-top: 140px;
    background-color: white;
    -webkit-box-shadow: -7px 10px 5px -1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: -7px 10px 5px -1px rgba(0, 0, 0, 0.4);
    box-shadow: -7px 10px 5px -1px rgba(0, 0, 0, 0.4);
  }

  .circle-content {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: -125px;
    right: 0;
    left: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #262e3b;
  }

  .meter-image {
    filter: invert(1);
  }

  .customer-header-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  .customer-name {
    font-size: 2.5rem;
    margin-top: 15px;
  }

  .org-name {
    font-size: 1.25em;
    font-weight: bold;
  }

  .account-information {
    padding-top: 15px;
  }

  .centerd-body-content {
    width: 80%;
    margin: auto;
  }

  .centerd-message-body-content {
    width: 50%;
    text-align: center;
    margin: auto;
  }

  .centerd-meter-message-body-content {
    width: 90%;
    text-align: center;
    margin: auto;
  }

  .previous-meter-reading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    margin: auto;
  }

  .previous-meter-reading .title,
  .new-meter-reading .title {
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: center;
  }

  .previous-meter-reading .reading-information {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    width: 90%;
    font-size: 1.5rem;
  }

  .new-meter-reading {
    width: 80%;
    margin: auto;
  }

  .new-meter-reading .field label {
    font-weight: normal !important;
  }

  .payment-options-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    margin: auto;
  }

  .payment-options-container .title {
    font-size: 2rem;
    margin-bottom: 15px;
    text-align: center;
  }

  .pay-with-paystar {
    margin-top: 1rem;
    height: 3rem;
  }

  .return-button {
    margin-top: 1rem;
    height: 3rem;
  }

  .submit-button {
    height: 3rem;
  }

  .submission-table {
    width: 100%;
  }

  .submission-table td:first-child {
    text-align: right;
    width: 60%;
  }

  .submission-table td:last-child {
    font-size: 1.5em;
    width: 40%;
  }

  .tiny-text {
    font-size: 0.674665rem;
  }

  .small-text {
    font-size: 1.1rem;
  }

  .medium-text {
    font-size: 1.4rem;
  }

  .large-text {
    font-size: 1.9rem;
  }

  .center-horizontal {
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .amount-due-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .amount-due-container .title {
    font-size: 2rem;
    margin-bottom: 15px;
    text-align: center;
  }

  .amount-due-container .measurement-title {
    font-size: 2rem;
    margin-top: 15px;
    margin-bottom: 10px;
    text-align: center;
  }

  .go-back-span {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }

  .amount-due-title {
    margin-bottom: 5px;
  }

  .amount-due-measure {
    margin-bottom: 15px;
  }

  .last-reading-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-top: 15px;
    width: 80%;
    margin: auto;
    font-size: 1.25em;
  }

  .terms-of-service {
    text-align: center;
    padding-bottom: 17px;
  }

  .logo {
    margin-left: auto;
    margin-right: auto;
  }
`;
