import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {PaymentSuccessful} from '../customers/payment-successful';
import {routes} from '.';
import {MeterReadingContainer} from '../customers/meter-reading-container';

export const CustomerRoutes = () => {
  return (
    <Switch>
      <Route path={routes.customer.meterReadingInput} exact>
        <MeterReadingContainer />
      </Route>
      <Route path={routes.customer.paymentSuccessful} exact>
        <PaymentSuccessful />
      </Route>
    </Switch>
  );
};
