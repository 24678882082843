import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {OrganizationsService} from '../api/generated';
import {BasicPage} from '../basic-page';
import {routes} from '../routes';
import {
  PagedDataTableConfig,
  usePagedDataTable,
  renderEditButton,
} from '../hooks/use-paged-data-table';
import {useHasAuthorization} from '../auth/authorization-helpers';

export const OrganizationListing = () => {
  const isAdmin = useHasAuthorization({
    role: ['Global Admin'],
  });

  const pagedDataTable = usePagedDataTable(
    OrganizationsService.getAll,
    TableConfig,
    {
      actions: (
        <Button as={Link} to={routes.portal.organizations.create} primary>
          <FontAwesomeIcon icon={faPlus} /> New Organization
        </Button>
      ),
    }
  );

  return (
    <BasicPage title="Organizations">
      {isAdmin ? pagedDataTable : null}
    </BasicPage>
  );
};

const TableConfig = PagedDataTableConfig(OrganizationsService.getAll, {
  columns: [
    {
      header: '',
      render: item => (
        <>
          {renderEditButton({
            item,
            descriptor: 'organizations',
            route: routes.portal.organizations.detail,
          })}
        </>
      ),
      cellProps: {
        collapsing: true,
      },
    },
    {
      header: 'Name',
      column: 'name',
    },
  ],
  searchFieldNames: ['name'],
  defaultSort: {
    column: 'name',
    direction: 'DESC',
  },
});
