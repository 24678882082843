import React from 'react';
import {ToastProvider} from 'react-toast-notifications';
import styled from '@emotion/styled/macro';
import {NotificationService} from '../utils/notification-service';

const Container = styled.div`
  position: fixed;
  top: 70px;
  right: 8px;
  max-height: 100%;
  z-index: 10001;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px;
`;

const Components = {Container};

export const ToastContainer: React.FC = ({children}) => (
  <ToastProvider components={Components}>
    <NotificationService />
    {children}
  </ToastProvider>
);
