import {faPlus, faTachometerAlt} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useMemo, useCallback, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Modal} from 'semantic-ui-react';
import {Form} from '../forms';
import {AccountsService, AccountSummaryDto} from '../api/generated';
import {BasicPage} from '../basic-page';
import {routes, buildPath} from '../routes';
import {
  PagedDataTableConfig,
  usePagedDataTable,
  renderViewButton,
  renderButton,
} from '../hooks/use-paged-data-table';
import {useOrganizationContext} from '../selectors';
import {AdvancedPagedRequest} from '../types';
import {useNotification} from '../hooks/use-notifications';

type FetchParams = Parameters<
  typeof AccountsService.getAllByOrganizationId
>['0'];

export const AccountListing = () => {
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const [updateAccountAfterImport, setUpdateAccountAfterImport] = useState(0);

  const notifications = useNotification();

  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const additionalParams = useMemo<FetchParams>(
    () => ({
      organizationId: organizationId,
    }),
    [organizationId]
  );

  const fetchAccounts = useCallback(
    (x: AdvancedPagedRequest<AccountSummaryDto>) =>
      AccountsService.getAllByOrganizationId({
        ...additionalParams,
        ...x,
        _: updateAccountAfterImport,
      } as FetchParams),
    [additionalParams, updateAccountAfterImport]
  );

  const pagedDataTable = usePagedDataTable(fetchAccounts, TableConfig, {
    actions: (
      <>
        <div style={{display: 'inline-block', marginRight: '10px'}}>
          <Form
            onSubmit={(e) => e.preventDefault}
            render={() => (
              <>
                <Modal
                  trigger={
                    <Button type="button" secondary onClick={handleOpen}>
                      Upload Accounts
                    </Button>
                  }
                  open={modalOpen}
                  onClose={handleClose}
                  size="tiny"
                  closeIcon
                >
                  <Modal.Content>
                    <h5>Accounts Upload</h5>
                    <Form.InputFileUrl
                      fieldName="file"
                      onDrop={async (file) => {
                        handleClose();
                        const response = await AccountsService.upload({
                          organizationId: organizationId,
                          file: file,
                        });

                        if (response.hasErrors) {
                          const errorMessage = response.errors.reduce(
                            (acc, error) => {
                              var result = (
                                acc + ` ${error.errorMessage}`
                              ).trim();
                              return result;
                            },
                            ''
                          );
                          notifications.error(errorMessage);
                        } else {
                          notifications.success(
                            'Accounts successfully imported!'
                          );
                          setUpdateAccountAfterImport(
                            updateAccountAfterImport + 1
                          );
                        }
                      }}
                    />
                  </Modal.Content>
                </Modal>
              </>
            )}
          ></Form>
        </div>
        <Button as={Link} to={routes.portal.accounts.create} primary>
          <FontAwesomeIcon icon={faPlus} /> New Account
        </Button>
      </>
    ),
  });

  return <BasicPage title="Accounts">{pagedDataTable}</BasicPage>;
};

const TableConfig = PagedDataTableConfig(AccountsService.getAll, {
  columns: [
    {
      header: '',
      render: (item) => (
        <>
          {renderViewButton({
            item,
            descriptor: 'account',
            route: routes.portal.accounts.dashboard,
          })}
          {renderButton({
            item,
            descriptor: 'Add Meter Reading',
            fontAwesomeIcon: faTachometerAlt,
            disabled: !item.hasMeterReadings,
            route:
              buildPath(routes.customer.meterReadingInput, {
                slug: item.organizationSlug,
              }) + `?accountNumber=${item.accountNumber}`,
          })}
        </>
      ),
      cellProps: {
        collapsing: true,
      },
    },
    {
      header: 'Account Number',
      column: 'accountNumber',
      sortable: 'accountNumber',
      cellProps: {
        collapsing: true,
      },
    },
    {
      header: 'Name',
      column: 'name',
      sortable: 'name',
    },
  ],
  searchFieldNames: ['accountNumber', 'name'],
  defaultSort: {
    column: 'accountNumber',
    direction: 'ASC',
  },
});
