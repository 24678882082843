import React, {useState} from 'react';
import {useAsync} from 'react-use';
import {Button, Message} from 'semantic-ui-react';
import {customerStyles} from './customer-styles';
import {AsyncStateContainer} from '../components/async-state-container';
import {useHasAuthorization} from '../auth/authorization-helpers';
import {
  AccountForCustomer,
  AccountsService,
  MeterReadingSummaryDto,
  RateTablesService,
} from '../api/generated';
import {useRouteMatch} from 'react-router-dom';
import {useQuery} from '../hooks/use-query';
import {useOrganizationContext} from '../selectors';
import {AccountSearch} from './account-search';
import {MeterReadingInput} from './meter-reading-input';
import {Image} from 'semantic-ui-react';
import meterReadingLogo from '../images/meter-reader-balanced-black.png';
import e3logo from '../assets/e3-logo.png';
import {MeterReadingSubmitted} from './meter-reading-submitted';
import moment from 'moment';

export const MeterReadingContainer = React.memo(() => {
  const context = useOrganizationContext();
  const organizationId = context.organizationId as number;

  const query = useQuery();
  const [accountNumber, setAccountNumber] = useState(
    query.get('accountNumber')
  );

  const match = useRouteMatch<{slug: string}>();
  const slug = match.params.slug;

  const isAdmin = useHasAuthorization({
    role: ['Global Admin', 'Organization Admin', 'User'],
  });

  const [meterReading, setMeterReading] = useState<MeterReadingSummaryDto>();

  const fetchAccount = useAsync(async () => {
    var account = !isAdmin
      ? await AccountsService.getByCurrentUser()
      : !accountNumber
      ? undefined
      : await AccountsService.getByAccountNumberForCustomerPortal({
          accountNumber: accountNumber,
          organizationSlug: slug,
        });

    setMeterReading(account?.data?.lastMeterReading);

    return account;
  }, [accountNumber, isAdmin, slug]);

  const fetchRateTable = useAsync(
    async () =>
      await RateTablesService.getByRateCode({
        rateCode: fetchAccount.value?.data?.rateCode as string,
        organizationId: organizationId,
      }),
    [fetchAccount.value, organizationId]
  );

  const hasAccountErrors = fetchAccount.value?.hasErrors;
  const accountError = fetchAccount.value?.errors[0]?.errorMessage;
  const rateTable = fetchRateTable.value?.data;
  const isPortalReading = meterReading?.isSubmittedThroughPortal;
  const daysSinceLastReading = meterReading
    ? moment().diff(moment.utc(meterReading?.createdDate), 'd')
    : -1;
  const isReadingWithin10Days = daysSinceLastReading < 10;

  return (
    <AsyncStateContainer {...fetchAccount}>
      <AsyncStateContainer {...fetchRateTable}>
        <MeterReadingWrapper>
          {isAdmin && !accountNumber ? (
            <AccountSearch setAccountNumber={setAccountNumber} />
          ) : isAdmin && accountError ? (
            <NoAccountFound setAccountNumber={setAccountNumber} />
          ) : !rateTable ? (
            <NoRateTable
              isAdmin={isAdmin}
              setAccountNumber={setAccountNumber}
            />
          ) : hasAccountErrors ? (
            <AccountIssue message={accountError} />
          ) : meterReading && isPortalReading && isReadingWithin10Days ? (
            <MeterReadingSubmitted
              account={fetchAccount.value?.data as AccountForCustomer}
              rateTable={rateTable}
              meterReading={meterReading}
              daysSinceLastReading={daysSinceLastReading}
            />
          ) : (
            <MeterReadingInput
              account={fetchAccount.value?.data as AccountForCustomer}
              lastMeterReading={meterReading as MeterReadingSummaryDto}
              setMeterReading={setMeterReading}
            />
          )}
        </MeterReadingWrapper>
      </AsyncStateContainer>
    </AsyncStateContainer>
  );
});

const MeterReadingWrapper = React.memo(({children}) => {
  return (
    <div css={customerStyles}>
      <div className="center-content-box">
        <div className="meter-reading-main-content">
          <div className="circle-content">
            <img
              className="meter-image"
              alt="meter reader logo"
              src={meterReadingLogo}
              height="250"
              width="250"
            />
          </div>
          {children}
        </div>
      </div>
      <div style={{width: 'fitContent'}}>
        <a
          href="https://elevator3.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            src={e3logo}
            size="small"
            alt="Elevator3 logo"
            className="logo"
            style={{marginTop: 10, marginBottom: 40}}
          />
        </a>
      </div>
    </div>
  );
});

const NoAccountFound = ({setAccountNumber}) => (
  <>
    <AccountIssue message="No account found." />
    <div style={{width: '90%', margin: 'auto'}}>
      <Button
        secondary
        fluid
        type="button"
        style={{marginTop: 10}}
        onClick={() => {
          setAccountNumber(undefined);
        }}
      >
        Try Another Account Number
      </Button>
    </div>
  </>
);

const NoRateTable = ({setAccountNumber, isAdmin}) => (
  <>
    <AccountIssue message="There is no rate table set up for your account, please contact your utility company." />
    {isAdmin && (
      <div style={{width: '90%', margin: 'auto'}}>
        <Button
          secondary
          fluid
          type="button"
          style={{marginTop: 10}}
          onClick={() => {
            setAccountNumber(undefined);
          }}
        >
          Try Another Account Number
        </Button>
      </div>
    )}
  </>
);

const AccountIssue = ({message}) => (
  <div style={{width: '90%', margin: 'auto'}}>
    <Message
      negative
      className="centered-message-body-content"
      content={message}
    />
  </div>
);
