import React, {useState} from 'react';
import {Message, Modal} from 'semantic-ui-react';
import {Form} from '../forms';
import {} from '../api/generated/enums';
import {css} from '@emotion/core';
import {IntegrationsService} from '../api/generated';

type SkipReadingModal = {
  accountId: number;
};

export const SkipReadingModal = (props: SkipReadingModal) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSubmit = async (values) => {
    const response = await IntegrationsService.postPaymentToPayStar({
      body: {
        accountId: props.accountId as number,
        freeFormAmount: values.freeFormAmount ?? 0,
      } as any,
    });

    if (response.hasErrors) {
      return response;
    }

    window.location.assign(response.data?.paymentLogInLink as string);
  };

  return (
    <div>
      <Modal
        css={styles}
        trigger={
          <Form.Button
            secondary
            fluid
            type="button"
            style={{ marginTop: 10 }}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Pay Without Submitting a Reading
          </Form.Button>
        }
        open={isModalOpen}
        closeOnEscape={isModalOpen}
        closeOnDimmerClick={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        closeIcon
      >
        <Modal.Content>
          <Form
            onSubmit={onSubmit}
            render={() => (
              <>
                <Form.Section title="Pay Without Submitting a Reading">
                    <Message warning header="Please Note" content="Meter readings should be supplied whenever possible." />
                    <FormFields />
                </Form.Section>

                <Form.Button type="submit" primary>
                  Pay Now
                </Form.Button>
              </>
            )}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

const FormFields = () => {
  return (
    <Form.InputCurrency
        fieldName="freeFormAmount"
        fieldLabel="How much would you like to pay?"
    />
  );
};

const styles = css`
  &.modal-notes {
    position: fixed;
    top: 80px;
    bottom: 1;
  }

  &.menu-item {
    flex-direction: row;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  &.modal-create-header {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  &.modal-create-header-item {
    flex-direction: row;
    display: flex;
    margin-left: auto;
  }
`;
