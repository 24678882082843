import React, {useContext} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {AuthContext, useUser} from './auth/use-auth';
import {PortalApp} from './portal-app';
import {appSubdirectory, rootRoutes} from './routes';
import {logger} from './utils/logger';
import { CustomerApp } from './customer-app';

export const App = () => {
  return (
    <Switch>
      <Route path={rootRoutes.callback}>
        <Callback />
      </Route>
      <Route path={rootRoutes.portal}>
        <PortalApp />
      </Route>
      <Route path={rootRoutes.customer}>
        <CustomerApp />
      </Route>
      <Route>
        <RoleLandingPageResolver />
      </Route>
    </Switch>
  );
};

const log = logger('auth');

const RoleLandingPageResolver = () => {
  const {redirectUrl = ''} = useContext(AuthContext);
  const user = useUser();

  let userDefaultUrl = rootRoutes.portal;

  // let slug: string;
  if (user.role === 'Quick Pay') {
    // slug = user.attributes?.organization_slug || '';
    userDefaultUrl = rootRoutes.customer;
    // userDefaultUrl = slug
    //   ? 'costomerz' //buildPath(routes.customer.dashboard, {slug})
    //   : rootRoutes.customer;
  }

  let url = redirectUrl || userDefaultUrl || rootRoutes.portal;
  const useUserDefault =
    url === '/' || url === appSubdirectory || url === `${appSubdirectory}/`;

  if (useUserDefault) {
    url = userDefaultUrl;
  }

  log.info('redirectUrl', {
    role: user.role,
    url,
    userDefaultUrl,
    redirectUrl,
    appSubdirectory,
  });

  return <Redirect to={url} />;
};

const Callback = () => {
  const context = useContext(AuthContext);
  const redirect = context.redirectUrl ?? '/';

  return <Redirect to={redirect} />;
};
