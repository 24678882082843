import React from 'react';
import {Button} from 'semantic-ui-react';
import {Form} from '../forms';

export const AccountSearch = ({setAccountNumber}) => {
  return (
    <div style={{width: '90%', margin: 'auto'}}>
      <div className="centered-body-content">
        <div className="large-text">Search for an Account</div>
        <br />
        <Form
          onSubmit={(values) => {
            setAccountNumber(values.accountNumber);
          }}
          render={() => (
            <>
              <Form.Row>
                <Form.Input
                  fieldName="accountNumber"
                  fieldLabel="Account Number"
                  fluid
                ></Form.Input>
              </Form.Row>
              <Button primary fluid type="submit">
                Search
              </Button>
            </>
          )}
        ></Form>
      </div>
    </div>
  );
};
